import { Box, IconButton, Modal, Typography } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close';
import { MapComponent } from "../MapComponent";




const VehicleLocationModal = ({ vehicleLocationModal, setVehicleLocationModal }) => {

    const handleClose = () => {
        setVehicleLocationModal(false)
    }


    return (

        <Modal
            open={vehicleLocationModal}
            onClose={handleClose}
            

        >
            <Box sx={{ backgroundColor: "#F8F8F8", position: "absolute", top: "20vh", left: "40vw", width: "30vw", height: "auto", padding: "20px",borderRadius:"20px" }} >
                <Box sx={{ display: "flex", alignItems: "center" }} >
                    <Box sx={{ flex: 1 }} >
                        <Typography sx={{ color: "#283246", fontSize: "24px", fontWeight: "600" }}>Your Vehicle’s Position</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={handleClose} size="large" >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box>
                <MapComponent height={'400px'} width={'100%'} markerlocation={{lat:23.021230850628992,lng:72.56852385449737}} livelocation={true}/>
                </Box>
            </Box>
        </Modal>

    )
}

export default VehicleLocationModal