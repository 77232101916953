// src/slices/fleetManageSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from 'uuid'; // Assuming you're using uuid to generate unique IDs
import { fleetTableData } from "../features/Fleet/utils";


const initialState = {
  fleet: fleetTableData,
};

const fleetManageSlice = createSlice({
  name: "fleet",
  initialState,
  reducers: {
    updatedFleet: (state, action) => {
      state.driver = action.payload;
    },
   addFleet: (state, action) => {
      const fleetIndex = state.fleet.findIndex(fleet => fleet.id === action.payload.id);
  
      if (fleetIndex !== -1) {
          // Fleet exists, so update it
          state.fleet[fleetIndex] = {
              ...state.fleet[fleetIndex],
              ...action.payload,
          };
      } else {
          // Fleet does not exist, add new
          const newFleet = {
              ...action.payload,
              id: action.payload.id || `usr_${uuidv4()}`, // Use provided id or generate a new one
              uuid: `usr_${uuidv4().slice(0, 6)}`, // Generate a new UUID
              create_date: new Date().toISOString().slice(0, 10) // Set the create date
          };
          state.fleet.push(newFleet);
      }
  },
    deleteFleet: (state, action) => {
      const userId = action.payload;
      // Find the fleet in the fleets array
      const fleetToDelete = state.fleet.find(fleet => fleet.id === userId);

      if (fleetToDelete) {
        // Remove the fleet from the fleets array
        state.fleet = state.fleet.filter(fleet => fleet.id !== userId);
      }

    },
  },
});

export const { updatedFleet,deleteFleet,addFleet} = fleetManageSlice.actions;
export default fleetManageSlice.reducer;
