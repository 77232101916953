import { Typography } from "@mui/material"

const LoginOtp = ()=>{
    return(
        <>
        <Typography>Login otp</Typography>
        </>
    )
}

export default LoginOtp