import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ReusableCalendar from "../../components/Calendar";
import FilledButton from "../../components/MainButtons/FilledButton";
import { InputFieldWrapper } from "../../components/CustomInputWithLabel";
import { CustomSelect } from "../../components/CustomSelection";

const AssignVehicleDialog = ({
  open,
  onClose,
  onAssign,
  vehicles,
  drivers,
  errorsText,
  selectedTrip,
  setError,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedDriver, setSelectedDriver] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(1, "hour"));
  const [notes, setNotes] = useState("");

  // Use effect to pre-fill the modal when editing an existing trip
  useEffect(() => {
    if (selectedTrip) {
      setSelectedDriver(selectedTrip.title || "");
      setSelectedVehicle(selectedTrip.vehicle_qr_code || "");
      setStartDate(dayjs(selectedTrip.start));
      setEndDate(dayjs(selectedTrip.end));
      setNotes(selectedTrip.notes || "");
    }
  }, [selectedTrip]);

  const handleSubmit = () => {
    // Reset error before validation
    setError("");

    // Validation checks
    if (!selectedDriver || !selectedVehicle) {
      setError("Please select both a vehicle and a driver.");
      return;
    }
    if (endDate.isBefore(startDate)) {
      setError("End date must be after the start date.");
      return;
    }

    // Prepare assignment data
    const assignment = {
      vehicle: selectedVehicle,
      driver: selectedDriver,
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
      notes,
      id: selectedTrip?.id || null,
    };

    onAssign(assignment);
  };

  const handleDialogClose = () => {
    setSelectedDriver("");
    setSelectedVehicle("");
    setStartDate(dayjs());
    setEndDate(dayjs());
    setNotes("");
    setError("");
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      fullWidth
      PaperProps={{
        sx: { borderRadius: "1.25rem", width: "47rem" },
      }}
    >
      <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: 500 }}>
        {selectedDriver ? "Edit Trip Assignment" : "Add Trip Assignment"}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", width: "100%", gap: "2rem" }}>
          <CustomSelect
            label="Select Vehicle"
            value={selectedVehicle}
            onChange={(e) => setSelectedVehicle(e.target.value)}
            options={vehicles}
            name="selectedVehicle"
          />

          <CustomSelect
            label="Select Driver"
            value={selectedDriver}
            onChange={(e) => setSelectedDriver(e.target.value)}
            options={drivers}
            name="selectedDriver"
          />
        </Box>
        <ReusableCalendar
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          includeTime={true}
        />
        <InputFieldWrapper
          label="Notes (up to 40 characters)"
          name="notes"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Enter Notes"
          height={"50px"}
          width={"100%"}
          maxLength={40}
        />
      </DialogContent>
      <Typography sx={{ color: "red", fontSize: "0.9rem", ml: 3, mb: 2 }}>
        {errorsText}
      </Typography>
      <DialogActions sx={{ justifyContent: "center" }}>
        <FilledButton onClick={handleSubmit} sx={{width:'250px'}}>
          {selectedDriver ? "Save Changes" : "Create"}
        </FilledButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignVehicleDialog;
