import { useEffect, useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Box,
  Typography,
  TextField,
  useTheme,
  CircularProgress,
} from "@mui/material";
// import { useSnackbar } from "../hooks/Snackbar";
import { useDispatch } from "react-redux";
// import { loginEmail, setOtpNew, setOtpTimeStamp } from "../slices/authSlice";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axonslablogin from "../../assets/Images/axonslab.svg";
import axonslogin from "../../assets/Images/axonslogin.svg";
import axonslogin2 from "../../assets/Images/axonslogin2.svg";
import axonslogin3 from "../../assets/Images/axonslogin3.svg";
import axonslogin4 from "../../assets/Images/axonslogin4.svg";
import axonslogin5 from "../../assets/Images/axonslogin5.svg";
import axonslogin6 from "../../assets/Images/axonslogin6.svg";
import { useNavigate } from "react-router-dom";

const images = [
  axonslogin,
  axonslogin2,
  axonslogin3,
  axonslogin4,
  axonslogin5,
  axonslogin6,
];

const users = {
  "admin@axonslab.io": {
    role: "admin",
    password: "admin123", // You can change this as needed
  },
  "operator@axonslab.io": {
    role: "operator",
    password: "operator123", // You can change this as needed
  },
};

const LoginEmail = ({ setEmailAuth }) => {
  const [email, setEmail] = useState("");
  const [validationError, setValidationError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const user = users[email];

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //   const showSnackbar = useSnackbar();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const extendedImages = [...images, images[0]];

  const handleChangeCarousal = (index) => {
    if (index === images.length) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(index);
    }
  };

  useEffect(() => {
    if (currentSlide === 0) {
      const carouselElement = document.querySelector(".carousel");
      carouselElement.style.transition = "none";
      carouselElement.style.transform = "translate3d(0px, 0px, 0px)";

      setTimeout(() => {
        carouselElement.style.transition = "";
      }, 0);
    }
  }, [currentSlide]);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    if (user && emailRegex.test(email)) {
      // Store role in localStorage and dispatch user data to Redux
      setIsLoading(true);
      localStorage.setItem("role", user.role);
      setValidationError(false);
      setHelperText("");
  
      // Mock loading for 1-2 seconds before navigating
      setTimeout(() => {
        setIsLoading(false); // Reset loading state
        navigate("/dashboard/analytics");
      }, 1500); // Delay of 1500ms (1.5 seconds)
    } else {
      // alert("Invalid login credentials");
      setValidationError(true);
      setHelperText("Please enter a valid email address");
    }
  };
  

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <Grid
      container
      sx={{ bgcolor: "#FFFFFF", padding: "1rem", minHeight: "100vh" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
        }}
      >
        <Box>
          <img src={axonslablogin} alt="axonslablogin" />
        </Box>
        <Card
          sx={{
            width: { xs: "100%", sm: 440 },
            minHeight: 320,
            borderRadius: "25px",
            mt: 7,
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                fontSize: 22,
                fontWeight: 700,
                fontFamily: theme.typography.fontFamily,
                // color: theme.palette.secondary.main,
              }}
            >
              Welcome Back
            </Typography>
            <Box sx={{ padding: "0.6rem" }}>
              <Typography
                sx={{
                  fontFamily: theme.typography.fontFamily,
                  fontSize: "12px",
                  marginLeft: "0.4rem",
                  //   color: theme.palette.secondary.main,
                  display: "flex",
                }}
              >
                Email
              </Typography>
              <TextField
                sx={{
                  mt: 1,
                  mb: 1,
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    "& input": {
                      padding: "0.8rem",
                    },
                    "& fieldset": {
                      borderWidth: "2px", // Set border width
                    },
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: theme.palette.secondary.main,
                    },
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#d32f2f", // Red border for validation error
                    },
                  "& .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor: "#d32f2f", // Ensure red border remains on focus during error
                    },
                }}
                placeholder="Enter your email"
                error={validationError}
                onKeyDown={handleKeyDown}
                value={email}
                onChange={handleChange}
                helperText={helperText}
              />
            </Box>
            <Button
              variant="contained"
              disableRipple
              sx={{
                mt: 1,
                borderRadius: "12px",
                width: "80%",
                height: 55,
                background:
                  "linear-gradient(to right, rgba(83, 90, 106, 1) 40%, rgba(40, 50, 70, 1) 100%)",
                transition: "all 0.3s ease",
                "&:hover": {
                  background:
                    "linear-gradient(to right, rgba(60, 70, 90, 1) 40%, rgba(20, 30, 50, 1) 100%)",
                  transform: "scale(1.02)",
                  boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                },
                "&:active": {
                  transform: "scale(0.98)",
                  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.15)",
                },
              }}
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                "Login"
              )}
            </Button>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: { xs: "auto", md: "90vh" },
          mt: { xs: 5, md: 2 },
        }}
      >
        <Card
          sx={{
            width: "90%",
            borderRadius: "15px",
            marginLeft: "4rem",
          }}
        >
          <Carousel
            selectedItem={currentSlide}
            onChange={handleChangeCarousal}
            autoPlay
            infiniteLoop
            interval={2000}
            showThumbs={false}
            showStatus={false}
            showArrows={false}
            showIndicators={false}
          >
            {extendedImages.map((URL, index) => (
              <div className="slide" key={index}>
                <img alt="carousel_image" src={URL} />
              </div>
            ))}
          </Carousel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginEmail;
