import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// External Library Imports
import {
  Avatar,
  Box,
  Card,
  Paper,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import { CSVLink } from "react-csv";

// Internal Module Imports
import SearchTextField from "../../components/SearchTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import csv from "../../assets/Icons/csv.svg";
import fleetselect from "../../assets/Icons/fleetselect.svg";
import driverinfo from "../../assets/Icons/driverinfo.svg";

import UserTableComponent from "../UserManagement/UserTable";
import plus from "../../assets/Icons/plus.svg";
import statusicon from "../../assets/Icons/status.svg";
import VehicleSelect from "../../components/Select";
import { useNavigate } from "react-router-dom";
import { driverData, driverTableCellItem } from "./utils";
import AnimatedNumber from "../../components/AnimatedNumbers";
import AddDriverForm from "./AddDriverForm";
import { deleteDriver } from "../../slices/driverManageSlice";
import DeleteUserModal from "../../components/DeleteModal";
import VehicleSelectFleet from "../../components/Select/AllFleetSelect";
import { useSnackbar } from "../../hooks/Snackbar";

const tabStyles = {
  width: "191px",
  height: "40px",
  fontWeight: 500,
  fontSize: "1rem",
  textTransform: "none",
  color: "#F99494",
  "&.Mui-selected": {
    background: "linear-gradient(90deg, #535A6A 0%, #283246 100%)",
    color: "#FFFFFF",
    borderRadius: "12px",
  },
};

export const statusOption = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

export const Drivers = () => {
  const [searchInput, setSearchInput] = useState("");
  const [userDelete, setUserDelete] = useState(false);
  const [userToDelete, setUserToDelete] = useState("");
  const [driverToDelete, setDriverToDelete] = useState("");
  const [createDriver, setCreateDriver] = useState(false);
  const [editDriver, setEditDriver] = useState("");
  const [fleetSelect, setFleetSelect] = useState([]);
  const [status, setStatus] = useState("");
  const [flag, setFlag] = useState("");
  const [value, setValue] = useState(0);
  const { driver } = useSelector((state) => state.driverManage);
  const { fleet } = useSelector((state) => state.fleetManage);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const fleetOptions = fleet?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
  }));

  const handleDeleteUser = (userId) => {
    const driverName = driver.find((driver) => driver.id === userId);

    setUserDelete(true);
    setUserToDelete(userId);
    setDriverToDelete(driverName.driver_name);
  };

  const handleDeleteDriverClose = () => {
    setUserDelete(false);
  };

  const handleConfirmDeleteDriver = () => {
    setUserDelete(false);
    dispatch(deleteDriver(userToDelete));
    showSnackbar("Driver deleted successfully!", "success");
    setCreateDriver(false);
  };

  const handleFleetChange = (value) => {
    if (value.length) {
      setFleetSelect(value);
    } else {
      setFleetSelect([]);
    }
  };

  const handleStatusChange = (value) => {
    setStatus(value);
  };

  const handleFlagChange = (value) => {
    setFlag(value);
  };

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const filteredvehicless =
    searchInput || fleetSelect.length || status
      ? driver
          .filter((user) => {
            const matchesName = user?.driver_name
              ?.toLowerCase()
              .includes(searchInput?.toLowerCase() || "");
            const matchesFleet =
              fleetSelect && fleetSelect.length
                ? fleetSelect.some(
                    (fleet) =>
                      user?.fleet?.toLowerCase() === fleet.label.toLowerCase() // Update to use fleet label
                  )
                : true;
            const matchesStatus = user?.status
              ?.toLowerCase()
              .includes(status?.toLocaleLowerCase() || "");
            return matchesName && matchesFleet && matchesStatus;
          })
          .sort((a, b) => a.driver_name.localeCompare(b.driver_name))
      : driver; // Return full list if both search input and flag are empty

  const handleEditDriver = (userId) => {
    setCreateDriver(true);
    setEditDriver(userId);
  };

  const handleDetailPage = (userId) => {
    navigate(`/dashboard/drivers/${userId}`);
  };

  const handleChange = (event, newValue) => {
    setSearchInput("");
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between", // This ensures spacing is handled evenly
          gap: "0.5rem", // Adds space between cards if needed
          width: "100%",
        }}
      >
        {driverData.map((user) => (
          <Card
            sx={{
              width: "20%", // Adjust width to fit 4 items per line
              height: "50px",
              borderRadius: "10px", // Corner radius
              padding: "20px", // Padding inside the card
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between", // Space between elements
              boxShadow: "none",
              backgroundColor: "#FFFFFF", // Card background color
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography
                sx={{
                  fontSize: "0.875rem",
                  color: theme.palette.text.primary,
                  fontWeight: "400",
                  marginBottom: "0.5rem",
                }}
              >
                {user.name}
              </Typography>

              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: theme.palette.text.primary,
                  fontWeight: 700,
                }}
              >
                <AnimatedNumber value={user.count} />
              </Typography>
            </Box>
            <Box
              sx={{
                width: 55, // Background size
                height: 55,
                backgroundColor: "#97A1B61A", // Background color
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={user.icon} style={{ width: 35, height: 35 }} />
            </Box>
          </Card>
        ))}
      </Box>

      <Paper
        sx={{
          borderRadius: "1.25rem",
          backgroundColor: "#FFFFFF", // Card background color
          padding: "1.563rem",
          boxShadow: "none",
          gap: 2,
          mt: 2,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
          }}
        >
          <img
            src={driverinfo}
            style={{
              height: "22px",
              width: "22px",
              marginRight: "10px", // Adjust the gap here
            }}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem", // Adjust font size as needed
              color: theme.palette.text.primary, // White text color
            }}
          >
            Driver Information
          </Typography>
        </Box>

        <Box>
          <Box
            sx={{
              // border: "1px solid #DDDEE1",
              height: "42px",
              justifyContent: "space-between",
              p: 1,
              background: "#FFFFFF",
              display: "flex",
              borderRadius: "6px",
              mb: 5,
            }}
          >
            <Box sx={{ display: "flex" }}>
              <SearchTextField
                value={searchInput}
                onChange={handleSearchChange}
                sx={{
                  height: "35px",
                  width: "14.75rem",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "6px",
                    color: "#283246",
                    backgroundColor: "#FFF",
                    fontSize: "1rem",
                    "& fieldset": {
                      border: "1px solid #DDDEE1", // Border color
                    },
                  },
                }}
              />
              <VehicleSelectFleet
                label="Fleet"
                options={fleetOptions}
                icon={<img src={fleetselect} />} // Passing the icon dynamically
                onChange={handleFleetChange}
              />
              <VehicleSelect
                label="Status"
                options={statusOption}
                icon={<img src={statusicon} />} // Passing a different icon dynamically
                onChange={handleStatusChange}
              />
            </Box>
            <Box sx={{ display: "flex" }}>
              <CSVLink data={filteredvehicless} filename={"driver.csv"}>
                <FilledButton
                  startIcon={
                    <img src={csv} style={{ width: "18px", height: "18px" }} />
                  }
                  sx={{
                    width: "6.5rem",
                    height: "2.188rem",
                    borderRadius: "6px",
                    ml: 3,
                    mt: 0.3,
                    background: "#ffffff",
                    color: "#283246",
                    border: "1px solid #283246",
                    boxShadow: "none",
                    "&:hover": {
                      background: "transparent", // Transparent background on hover
                    },
                  }}
                >
                  Get Csv
                </FilledButton>
              </CSVLink>
              <Box>
                <FilledButton
                  startIcon={<img src={plus} />}
                  sx={{
                    width: "8rem",
                    height: "2.188rem",
                    borderRadius: "6px",
                    ml: 1,
                    mt: 0.3,
                  }}
                  onClick={() => setCreateDriver(true)}
                >
                  Add Driver
                </FilledButton>
              </Box>
            </Box>
          </Box>

          <AddDriverForm
            drawerOpen={createDriver}
            setDrawerOpen={setCreateDriver}
            handleDelete={handleDeleteUser}
            editDriver={editDriver}
            setEditDriver={setEditDriver}
          />
          <DeleteUserModal
            open={userDelete}
            handleClose={handleDeleteDriverClose}
            handleDeleteUser={handleConfirmDeleteDriver}
            title={
              <span>
                Are you sure you want to permanently remove{" "}
                <strong>{driverToDelete}</strong> ? This action cannot be undone
                and will remove all related data.
              </span>
            }
            header={"Confirm Driver Removal"}
            confirmText={"Yes, Delete"}
          />

          <UserTableComponent
            data={filteredvehicless}
            tableHeaders={driverTableCellItem}
            showActions={true}
            handleEdit={handleEditDriver}
            handleDelete={handleDeleteUser}
            handleRowClick={handleDetailPage}
          />
        </Box>
      </Paper>
    </>
  );
};
