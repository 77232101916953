import { Box, Paper, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FilledButton from "../../components/MainButtons/FilledButton";
import SearchTextField from "../../components/SearchTextField";
import plus from "../../assets/Icons/plus.svg";
import UserTableComponent from "../UserManagement/UserTable";
import { fleetTableCellItem } from "./utils";
import { useDispatch, useSelector } from "react-redux";
import fleetinfo from "../../assets/Icons/fleetinfo.svg";
import AddFleetForm from "./AddFleetForm";
import DeleteUserModal from "../../components/DeleteModal";
import { deleteFleet } from "../../slices/fleetManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import DatePickerDemo from "../../components/ReusableDatePicker/Date";

export const FleetManagment = () => {
  const [searchInput, setSearchInput] = useState("");
  const [createFleet, setCreateFleet] = useState(false);
  const [editUser, setEditUser] = useState("");
  const [userDelete, setUserDelete] = useState(false);
  const [fleetDelete, setFleetDelete] = useState("");
  const [userToDelete, setUserToDelete] = useState("");

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const showSnackbar = useSnackbar();

  const { fleet } = useSelector((state) => state.fleetManage);

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value);
  };

  const handleCreateFleet = () => {
    setCreateFleet(true);
  };

  const handleDeleteFleetClose = () => {
    setUserDelete(false);
  };

  const handleEditUser = (userId) => {
    setCreateFleet(true);
    setEditUser(userId);
  };

  const handleDeleteUser = (userId) => {
    const fleetToDelete = fleet.find((fleet)=>fleet.id===userId)
    setUserDelete(true);
    setUserToDelete(userId);
    setFleetDelete(fleetToDelete.fleet_name)
  };

  const handleConfirmDeleteFleet = () => {
    setUserDelete(false);
    dispatch(deleteFleet(userToDelete));
    setCreateFleet(false)
    showSnackbar("Fleet deleted successfully!", "success");
  };

  const filteredFleets = searchInput
    ? fleet
        .filter((user) => {
          const matchesName = user?.fleet_name
            ?.toLowerCase()
            .includes(searchInput?.toLowerCase() || "");
          return matchesName;
        })
        .sort((a, b) => a.fleet_name.localeCompare(b.fleet_name))
    : fleet; // Return full list if both search input and flag are empty

  return (
    <>
      <Paper
        sx={{
          borderRadius: "1.25rem",
          background: "#FFFFFF",
          padding: "1.563rem",
          boxShadow: "none",
          minHeight: "90vh",
        }}
      >
        {/* <DatePickerDemo/> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 2,
            borderRadius: "15px 100px 0 6px",
          }}
        >
          {/* <Avatar src={fleetinfo} sx={{height:'22px',width:'22px'}}/> */}
          <img
            src={fleetinfo}
            style={{
              height: "22px",
              width: "22px",
              marginRight: "10px", // Adjust the gap here
            }}
          />
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "1.2rem", // Adjust font size as needed
              color: theme.palette.text.primary, // White text color
            }}
          >
            Fleet Information
          </Typography>
        </Box>
        <Box
          sx={{
            // border: "1px solid #DDDEE1",
            height: "42px",
            justifyContent: "space-between",
            p: 1,
            background:'#FFFFFF',
            display: "flex",
            borderRadius: "6px",
            mb: 5,
          }}
        >
          <Box>
            <SearchTextField
              value={searchInput}
              onChange={handleSearchChange}
            />
          </Box>
          <Box onClick={handleCreateFleet}>
            <FilledButton
              startIcon={
                <img src={plus} style={{ width: "18px", height: "18px" }} />
              }
              sx={{
                width: "130px",
                height: "35px",
                borderRadius: "6px",
                ml: 3,
              }}
            >
              Add Fleet
            </FilledButton>
          </Box>
        </Box>
        <AddFleetForm
          drawerOpen={createFleet}
          setDrawerOpen={setCreateFleet}
          editUser={editUser}
          setEditUser={setEditUser}
          handleDelete={handleDeleteUser}
        />

        <UserTableComponent
          data={filteredFleets}
          tableHeaders={fleetTableCellItem}
          showActions={true}
          handleEdit={handleEditUser}
          handleDelete={handleDeleteUser}
          handleRowClick={handleEditUser}
        />

        <DeleteUserModal
          open={userDelete}
          handleClose={handleDeleteFleetClose}
          handleDeleteUser={handleConfirmDeleteFleet}
          header={"Confirm Fleet Deletion"}
          title={
            <span>Are you sure you want to permanently delete the <strong>{fleetDelete}</strong>? This action cannot be undone and will remove all related data.</span>}
          confirmText={"Yes, Delete"}
        />
      </Paper>
    </>
  );
};
