import React, { useState } from "react";
import {
  Box,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  useTheme,
  Divider,
  FormHelperText,
} from "@mui/material";
import crossicon from "../../assets/Icons/drawercross.svg";
import FilledButton from "../../components/MainButtons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "../../hooks/Snackbar";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import attachmentplus from "../../assets/Icons/attachmentplus.svg"
import { addTicket } from "../../slices/workorderManageSlice";

const requiredFields = [
  "vehicle_type",
  "fleet",
  "vehicle_name",
  "issue_type",
  "issue_description",
];

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
  display: "flex",
};

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    // Apply responsive width based on breakpoints
    width: {
      xs: "100px", // Width for small screens
      sm: "300px", // Width for medium screens
      md: "400px", // Width for large screens
      lg: "300px",
      xl: "400px",
    },
  },

};

const selectStyle = {
  borderRadius: "10px",
  height: "40px",
  width: {
    xs: "100px", // Width for small screens
    sm: "300px", // Width for medium screens
    md: "400px", // Width for large screens
    lg: "300px",
    xl: "400px",
  },
};



export const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  required = true, // Added a new prop to control the asterisk display
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} {""}
        {required && (
          <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
        )}
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        sx={textFieldStyle}
        error={!!error}
        placeholder={placeholder}
      />

      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  required = true, // Added a new prop to control the asterisk display
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label}
        {required && (
          <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
        )}
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={selectStyle}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, // Adjust this value to control the height of the visible area
            },
          },
        }}
        name={name}
        error={!!error}
        displayEmpty
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const AddWorkOrderForm = ({ drawerOpen, setDrawerOpen }) => {
  // State to manage the form fields, including the file attachment
  const [vehicleData, setVehicleData] = useState({
    vehicle_type: "",
    fleet: "",
    vehicle_name: "",
    issue_type: "",
    issue_description: "",
    issue_attachment: null, // For storing the file
  });

  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});
  const { driver } = useSelector((state) => state.driverManage);
  const { fleet } = useSelector((state) => state.fleetManage);
  const { issueTypeList } = useSelector((state) => state.workorderdata);

  const showSnackbar = useSnackbar();
  const dispatch = useDispatch();
  const theme = useTheme();

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setDrawerOpen(open);
    setErrors({});
    setVehicleData({
      vehicle_type: "",
      fleet: "",
      vehicle_name: "",
      issue_type: "",
      issue_description: "",
      issue_attachment: null,
    });
  };

  const fleetOptions = fleet?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
  }));

  const issueOptions = issueTypeList?.map((issue) => ({
    label: issue.name,
    value: issue.name,
  }));

  const handleChange = (field, value) => {
    setVehicleData((prevState) => ({
      ...prevState,
      [field]: value,
    }));

    // Clear errors on change
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    
    if (file) {
      setVehicleData((prevState) => ({
        ...prevState,
        issue_attachment: file, // Store the file in state
      }));
      setFile(file);
    }
  };

  const handleTicketCreate = () => {
    let isValid = true;
    const newErrors = {};

    // Check only required fields
    requiredFields.forEach((key) => {
      if (!vehicleData[key]) {
        isValid = false;
        newErrors[key] = "This field is required";
      }
    });

    setErrors(newErrors);

    if (isValid) {
      console.log(vehicleData, "vehicleData");
      dispatch(addTicket(vehicleData));
      setDrawerOpen(false);
      showSnackbar("Awesome! Your ticket is live!", "success");
      setErrors({});
      setVehicleData({
        vehicle_type: "",
        fleet: "",
        vehicle_name: "",
        issue_type: "",
        issue_description: "",
        issue_attachment: null, // Reset the file after submission
      });
    }
  };

    

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      transitionDuration={{
        enter: 500,
        exit: 500,
      }}
      PaperProps={{
        sx: {
          width: { lg: "35%", xl: "35%" },
          boxSizing: "border-box",
          background: theme.palette.text.quaternary,
          border: "none",
          borderRadius: "1rem",
        },
      }}
      sx={{
        width: { lg: "35%", xl: "35%" },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "100%",
            padding: "20px",
            backgroundColor: "#ffff",
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 0.5,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: "1rem", mt: 0.5, fontWeight: 600 }}>
              Create Ticket
            </Typography>
            <IconButton
              onClick={toggleDrawer(false)}
              sx={{ color: theme.palette.text.primary }}
            >
              <img src={crossicon} alt="Close" />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { lg: "1rem", xl: "1.6rem" },
              mt: 2,
            }}
          >
            <CustomSelect
              label="Vehicle Type"
              value={vehicleData.vehicle_type}
              onChange={(e) => handleChange("vehicle_type", e.target.value)}
              error={errors.vehicle_type}
              options={fleetOptions}
              name="vehicle_type"
            />
            <CustomSelect
              label="Fleet"
              value={vehicleData.fleet}
              onChange={(e) => handleChange("fleet", e.target.value)}
              error={errors.fleet}
              options={fleetOptions}
              name="fleet"
            />
            <InputFieldWrapper
              label="Vehicle Name"
              name="vehicle_name"
              value={vehicleData.vehicle_name}
              onChange={(e) => handleChange("vehicle_name", e.target.value)}
              error={errors.vehicle_name}
              placeholder="Enter Vehicle Name"
            />
            <CustomSelect
              label="Issue Type"
              value={vehicleData.issue_type}
              onChange={(e) => handleChange("issue_type", e.target.value)}
              error={errors.issue_type}
              options={issueOptions}
              name="issue_type"
            />
            <InputFieldWrapper
              label="Issue Description"
              name="issue_description"
              value={vehicleData.issue_description}
              onChange={(e) =>
                handleChange("issue_description", e.target.value)
              }
              error={errors.issue_description}
              placeholder="Enter Issue Description"
            />

            {/* File Upload Button */}
            <Box>
              <InputLabel sx={inputLabel}>Issue Attachment</InputLabel>
              <Box sx={{display:'flex',justifyContent:'center',borderStyle: 'dotted', borderWidth: '4px', borderColor: '#DDDEE1',cursor: "pointer",p:4}}
                              onClick={() => document.getElementById("upload-file").click()} // Trigger file input on box click

              >
              <input
                type="file"
                accept="image/*, .pdf, .doc, .docx"
                style={{ display: "none" }}
                id="upload-file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload-file">
                <FilledButton
                sx={{width:'150px',height:'50px',background:'none',boxShadow:'none',color:"#97A1B6",'&:hover': {
                  background: 'none',
                  boxShadow: 'none',
                }}}
                >
                  <img src={attachmentplus}/>
                </FilledButton>
                <Typography sx={{color:"#97A1B6",}}>{vehicleData.issue_attachment && vehicleData.issue_attachment.name}</Typography>
                <Typography sx={{color:"#97A1B6",mt:2,fontSize:'1rem',display:'flex',justifyContent:'center'}}>Click to pick files</Typography>
              </label>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              mt: {
                lg: 5,
                xl: 12,
              },
            }}
          >
            <FilledButton sx={{ width: "150px" }} onClick={handleTicketCreate}>
              Create
            </FilledButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddWorkOrderForm;
