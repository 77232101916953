import totalvehicle from "../../assets/Icons/totalvehicle.svg";
import availableVehicle from "../../assets/Icons/availableIcon.svg";
import unassginedVehicle from "../../assets/Icons/unassignedvehicle.svg";
import inusevehicle from "../../assets/Icons/inusevehicle.svg";
import mapicon from "../../assets/Icons/location.svg";
import iotflagicon from "../../assets/Icons/iotfaultflag.svg";
import distancetravelled from "../../assets/Icons/distancetravelled.svg";
import totaltrip from "../../assets/Icons/totaltrip.svg";
import idletime from "../../assets/Icons/idletime.svg";
import avgtripduration from "../../assets/Icons/avgtripduration.svg";

export const tripTableCellItem = [
  { label: "Trip Id", key: "trip_id" },
  { label: "Driver", key: "driver" },
  { label: "Start Date/Time", key: "start_date_time" },
  { label: "End Date/Time", key: "end_date_time" },
  { label: "Duration", key: "duration" },
];

export const vehicleData = [
  { name: "Total Vehicles", count: "60", icon: totalvehicle },
  { name: "Available", count: "50", icon: availableVehicle },
  { name: "In Use", count: "20", icon: inusevehicle },
  { name: "Unassigned Vehicles", count: "8", icon: unassginedVehicle },
];

export const fleetOptions = [
  { value: "shyamal_fleet", label: "Shyamal" },
  { value: "maninagar_fleet", label: "Maninagar" },
  { value: "satellite_fleet", label: "Satellite" },
];

export const statusOption = [
  { value: "in_transit", label: "In Transit" },
  { value: "idle", label: "Idle" },
  { value: "maintenance", label: "In Maintenance" },
  { value: "out_of_service", label: "Out of Service" },
  { value: "insurance_due", label: "Insurance Due" },
];

export const alertOption = [
  {
    value: "service_due",
    label: "Service Due",
  },
  {
    value: "insurance_due",
    label: "Insurance Due",
  },
];

export const userVehicleEdit = [
  { name: "Total Trips", count: "8", icon: totaltrip },
  { name: "Distance Travelled", count: "10.50 km", icon: distancetravelled },
  { name: "Idle Time ", count: "5 hr", icon: idletime },
  { name: "Avg Trip Duration ", count: "10 Minute", icon: avgtripduration },
];

export const vehicleTableCellItem = [
  { label: "Name", key: "vehicle_name" },
  { label: "Vehicle QR Code", key: "vehicle_qr" },
  { label: "IMEI", key: "imei" },
  { label: "Driver", key: "driver" },
  { label: "Battery", key: "battery" },
  { label: "Alerts", key: "alerts" },
  { label: "Location", key: "location" },
  { label: "Vehicle Action", key: "vehicle_action" },
];

export const vehicleTableData = [
  {
    user_name: "Alice Johnson",
    vehicle_qr: "Vehicle001",
    imei: "e00fce68a0ad7b4549",
    battery: "90",
    id: "usr_Xyz1234abcDEF2",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fuelType: "Petrol",
    status: "Idle",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    vehicle_type: "Cybertruck",
    fleet: "King Street Transit",
    vehicle_name: "GenCar2",
    fuel_type: "Electric",
    driver_id: "usr_Bob1234SmithXYZ",
    vehicle_identifier: "VEH12345X",
    driver: "Bob Smith",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_001",
    imei: "12345697",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.017056,
    lng: 72.615138,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "GenCar2",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Bob Smith",
    vehicle_qr: "Vehicle002",
    imei: "d35bfc78e9bc8d4453",
    battery: "85",
    id: "usr_Yty4567defGHI3",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    status: "In Transit",
    flag: "Iot fault",
    vehicle_type: "Sedan",
    fuelType: "Diesel",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan001",
    fuel_type: "Diesel",
    driver_id: "usr_Alice5678JohnABC",
    vehicle_identifier: "XTR56789Z",
    driver: "Alice Johnson",
    qr_code: "QR987654321",
    iot_type: "Axons Micro",
    iot_name: "IOT_002",
    imei: "65432198",
    bluetooth_flow: "Yes",
    last_active: "Yesterday",
    lat: 23.06028,
    lng: 72.570034,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-8",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan001",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Catherine Lee",
    vehicle_qr: "Vehicle003",
    imei: "a23fd459df4bb67534",
    battery: "75",
    id: "usr_Abc9876ghiJKL4",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    alerts: [],
    fleet: "Satellite",
    status: "In Transit",
    flag: "Battery Critical",
    vehicle_type: "Hatchback",
    fleet: "Jackson Line Metro",
    fuelType: "Electric",
    vehicle_name: "Hatchback003",
    fuel_type: "Hybrid",
    driver_id: "usr_John7890DoeXYZ",
    vehicle_identifier: "CAR09876Q",
    driver: "John Doe",
    qr_code: "QR654321123",
    iot_type: "Axons Micro",
    iot_name: "IOT_003",
    imei: "78912345",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.057443,
    lng: 72.553011,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback003",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "David Clark",
    vehicle_qr: "Vehicle004",
    imei: "f45eab78ca2d5c7834",
    battery: "60",
    id: "usr_Jkl5432mnoPQR5",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: [],
    fleet: "Shyamal",
    status: "Idle",
    flag: "IOT Fault",
    vehicle_type: "SUV",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TRK43210P",
    fuelType: "Electric",
    vehicle_name: "SUV004",
    fuel_type: "Gasoline",
    driver_id: "usr_Sarah2345WillDEF",
    driver: "Sarah Wilson",
    qr_code: "QR123123123",
    iot_type: "Axons Micro Pro",
    iot_name: "IOT_004",
    imei: "98765432",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.037269,
    lng: 72.556233,

    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV004",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Sarah Wilson",
    vehicle_qr: "Vehicle005",
    imei: "c67bfa789bcd1d3542",
    battery: "50",
    id: "usr_Qrs1290pqrSTU6",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Maninagar",
    vehicle_identifier: "MTR23456H",
    status: "Idle",
    flag: "IOT Fault",
    fuelType: "Diesel",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan005",
    fuel_type: "Electric",
    driver_id: "usr_James4567BrownXYZ",
    driver: "James Brown",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_005",
    imei: "98765432",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.026803,
    lng: 72.619063,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan005",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Franklin Perez",
    vehicle_qr: "Vehicle006",
    imei: "g23acd579e2b3f6745",
    battery: "95",
    id: "usr_Wxy6789stuVWX7",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "SUV87654L",
    alerts: [],
    status: "Idle",
    flag: "",
    vehicle_type: "Cybertruck",
    fleet: "Martin Urban Transit",
    vehicle_name: "Cybertruck006",
    fuel_type: "Electric",
    fuelType: "Diesel",
    driver_id: "usr_Emily6789DavisDEF",
    driver: "Sarah Wilson",
    qr_code: "QR345678901",
    iot_type: "Axons Micro",
    iot_name: "IOT_006",
    imei: "65432198",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.041675,
    lng: 72.643038,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Cybertruck006",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Grace Thompson",
    vehicle_qr: "Vehicle007",
    imei: "h56efg790b4e2d8794",
    battery: "70",
    id: "usr_Uvw4321xyzABC8",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "BUS12390F",
    status: "In Transit",
    fuelType: "Diesel",
    alerts: ["Service Due"],
    alerts_description: "Scheduled maintenance or service is due soon.",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan007",
    fuel_type: "Diesel",
    driver_id: "usr_Mike1234JonesXYZ",
    driver: "Mike Jones",
    qr_code: "QR789456123",
    lat: 22.963306,
    lng: 72.494798,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan007",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Henry Walker",
    vehicle_qr: "Vehicle008",
    imei: "i78hij890d5f2b9803",
    battery: "40",
    id: "usr_Zab0987defDEF9",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "VAN54321E",
    status: "In Transit",
    fuelType: "Petrol",
    alerts: [],
    alerts_description: "",
    vehicle_type: "SUV",
    fleet: "Maninagar Fleet",
    vehicle_name: "SUV008",
    fuel_type: "Diesel",
    driver_id: "usr_Linda5678ClarkABC",
    driver: "Linda Clark",
    qr_code: "QR567890123",
    iot_type: "Axons Micro",
    iot_name: "IOT_008",
    imei: "32145698",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.018496,
    lng: 72.577618,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV008",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Isabella Martinez",
    vehicle_qr: "Vehicle009",
    imei: "j12klm891c6g3h0912",
    battery: "80",
    id: "usr_Ghi8765jklGHI0",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    status: "In Transit",
    alerts: [],
    alerts_description:
      "Payment deadline to maintain active insurance coverage.",
    vehicle_type: "Hatchback",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TMP65748R",
    vehicle_name: "Hatchback009",
    fuelType: "Electric",
    fuel_type: "Hybrid",
    driver_id: "usr_Robert7890LeeXYZ",
    driver: "Robert Lee",
    qr_code: "QR654789321",
    iot_type: "Axons Micro",
    iot_name: "IOT_009",
    imei: "78932156",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 22.96067,
    lng: 72.515426,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback009",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Jack White",
    vehicle_qr: "Vehicle010",
    imei: "k34nop892f7j4i1201",
    battery: "65",
    id: "usr_Nop5432klmNOP1",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Shyamal",
    vehicle_identifier: "AUT31245W",
    status: "In Transit",
    flag: "",
    vehicle_type: "Sedan",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan010",
    fuelType: "Petrol",
    fuel_type: "Electric",
    driver_id: "usr_Nancy2345HillDEF",
    alerts: [],
    driver: "Nancy Hill",
    qr_code: "QR123098765",
    iot_type: "Axons Micro",
    iot_name: "IOT_010",
    imei: "65478923",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 22.960269,
    lng: 72.62067,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan010",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ]
  },


  //new data from here 11-20
  {
    user_name: "Alice Johnson",
    vehicle_qr: "Vehicle001",
    imei: "e00fce68a0ad7b4549",
    battery: "90",
    id: "usr_Xyz1234abcDEF2",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fuelType: "Petrol",
    status: "Idle",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    vehicle_type: "Cybertruck",
    fleet: "King Street Transit",
    vehicle_name: "GenCar2",
    fuel_type: "Electric",
    driver_id: "usr_Bob1234SmithXYZ",
    vehicle_identifier: "VEH12345X",
    driver: "Bob Smith",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_001",
    imei: "12345697",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.02514652215116,
    lng: 72.5898160049272,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "GenCar2",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Bob Smith",
    vehicle_qr: "Vehicle002",
    imei: "d35bfc78e9bc8d4453",
    battery: "85",
    id: "usr_Yty4567defGHI3",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    status: "In Transit",
    flag: "Iot fault",
    vehicle_type: "Sedan",
    fuelType: "Diesel",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan001",
    fuel_type: "Diesel",
    driver_id: "usr_Alice5678JohnABC",
    vehicle_identifier: "XTR56789Z",
    driver: "Alice Johnson",
    qr_code: "QR987654321",
    iot_type: "Axons Micro",
    iot_name: "IOT_002",
    imei: "65432198",
    bluetooth_flow: "Yes",
    last_active: "Yesterday",
    lat: 23.025810809633043,
    lng: 72.58869949698834,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-8",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan001",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Catherine Lee",
    vehicle_qr: "Vehicle003",
    imei: "a23fd459df4bb67534",
    battery: "75",
    id: "usr_Abc9876ghiJKL4",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    alerts: [],
    fleet: "Satellite",
    status: "In Transit",
    flag: "Battery Critical",
    vehicle_type: "Hatchback",
    fleet: "Jackson Line Metro",
    fuelType: "Electric",
    vehicle_name: "Hatchback003",
    fuel_type: "Hybrid",
    driver_id: "usr_John7890DoeXYZ",
    vehicle_identifier: "CAR09876Q",
    driver: "John Doe",
    qr_code: "QR654321123",
    iot_type: "Axons Micro",
    iot_name: "IOT_003",
    imei: "78912345",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.025900573739335,
    lng: 72.59051074426401,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback003",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "David Clark",
    vehicle_qr: "Vehicle004",
    imei: "f45eab78ca2d5c7834",
    battery: "60",
    id: "usr_Jkl5432mnoPQR5",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: [],
    fleet: "Shyamal",
    status: "Idle",
    flag: "IOT Fault",
    vehicle_type: "SUV",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TRK43210P",
    fuelType: "Electric",
    vehicle_name: "SUV004",
    fuel_type: "Gasoline",
    driver_id: "usr_Sarah2345WillDEF",
    driver: "Sarah Wilson",
    qr_code: "QR123123123",
    iot_type: "Axons Micro Pro",
    iot_name: "IOT_004",
    imei: "98765432",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.02495419599957,
    lng: 72.58542256259287,

    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV004",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Sarah Wilson",
    vehicle_qr: "Vehicle005",
    imei: "c67bfa789bcd1d3542",
    battery: "50",
    id: "usr_Qrs1290pqrSTU6",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Maninagar",
    vehicle_identifier: "MTR23456H",
    status: "Idle",
    flag: "IOT Fault",
    fuelType: "Diesel",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan005",
    fuel_type: "Electric",
    driver_id: "usr_James4567BrownXYZ",
    driver: "James Brown",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_005",
    imei: "98765432",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.024010931718777,
    lng: 72.58656587049228,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan005",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Franklin Perez",
    vehicle_qr: "Vehicle006",
    imei: "g23acd579e2b3f6745",
    battery: "95",
    id: "usr_Wxy6789stuVWX7",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "SUV87654L",
    alerts: [],
    status: "Idle",
    flag: "",
    vehicle_type: "Cybertruck",
    fleet: "Martin Urban Transit",
    vehicle_name: "Cybertruck006",
    fuel_type: "Electric",
    fuelType: "Diesel",
    driver_id: "usr_Emily6789DavisDEF",
    driver: "Sarah Wilson",
    qr_code: "QR345678901",
    iot_type: "Axons Micro",
    iot_name: "IOT_006",
    imei: "65432198",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.027965611087254,
    lng: 72.58472966093927,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Cybertruck006",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Grace Thompson",
    vehicle_qr: "Vehicle007",
    imei: "h56efg790b4e2d8794",
    battery: "70",
    id: "usr_Uvw4321xyzABC8",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "BUS12390F",
    status: "In Transit",
    fuelType: "Diesel",
    alerts: ["Service Due"],
    alerts_description: "Scheduled maintenance or service is due soon.",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan007",
    fuel_type: "Diesel",
    driver_id: "usr_Mike1234JonesXYZ",
    driver: "Mike Jones",
    qr_code: "QR789456123",
    lat: 23.02948433862123,
    lng: 72.58449232276342,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan007",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Henry Walker",
    vehicle_qr: "Vehicle008",
    imei: "i78hij890d5f2b9803",
    battery: "40",
    id: "usr_Zab0987defDEF9",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "VAN54321E",
    status: "In Transit",
    fuelType: "Petrol",
    alerts: [],
    alerts_description: "",
    vehicle_type: "SUV",
    fleet: "Maninagar Fleet",
    vehicle_name: "SUV008",
    fuel_type: "Diesel",
    driver_id: "usr_Linda5678ClarkABC",
    driver: "Linda Clark",
    qr_code: "QR567890123",
    iot_type: "Axons Micro",
    iot_name: "IOT_008",
    imei: "32145698",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat:23.030520444875478,
    lng: 72.58931019026994,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV008",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Isabella Martinez",
    vehicle_qr: "Vehicle009",
    imei: "j12klm891c6g3h0912",
    battery: "80",
    id: "usr_Ghi8765jklGHI0",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    status: "In Transit",
    alerts: [],
    alerts_description:
      "Payment deadline to maintain active insurance coverage.",
    vehicle_type: "Hatchback",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TMP65748R",
    vehicle_name: "Hatchback009",
    fuelType: "Electric",
    fuel_type: "Hybrid",
    driver_id: "usr_Robert7890LeeXYZ",
    driver: "Robert Lee",
    qr_code: "QR654789321",
    iot_type: "Axons Micro",
    iot_name: "IOT_009",
    imei: "78932156",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.030556709155476,
    lng: 72.5867464854062,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback009",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Jack White",
    vehicle_qr: "Vehicle010",
    imei: "k34nop892f7j4i1201",
    battery: "65",
    id: "usr_Nop5432klmNOP1",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Shyamal",
    vehicle_identifier: "AUT31245W",
    status: "In Transit",
    flag: "",
    vehicle_type: "Sedan",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan010",
    fuelType: "Petrol",
    fuel_type: "Electric",
    driver_id: "usr_Nancy2345HillDEF",
    alerts: [],
    driver: "Nancy Hill",
    qr_code: "QR123098765",
    iot_type: "Axons Micro",
    iot_name: "IOT_010",
    imei: "65478923",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.02851840714804,
    lng: 72.59040367006983,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan010",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ]
  },

  //21-30
  {
    user_name: "Alice Johnson",
    vehicle_qr: "Vehicle001",
    imei: "e00fce68a0ad7b4549",
    battery: "90",
    id: "usr_Xyz1234abcDEF2",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fuelType: "Petrol",
    status: "Idle",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    vehicle_type: "Cybertruck",
    fleet: "King Street Transit",
    vehicle_name: "GenCar2",
    fuel_type: "Electric",
    driver_id: "usr_Bob1234SmithXYZ",
    vehicle_identifier: "VEH12345X",
    driver: "Bob Smith",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_001",
    imei: "12345697",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.026522818248623,
    lng: 72.5918435512271   ,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "GenCar2",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Bob Smith",
    vehicle_qr: "Vehicle002",
    imei: "d35bfc78e9bc8d4453",
    battery: "85",
    id: "usr_Yty4567defGHI3",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    status: "In Transit",
    flag: "Iot fault",
    vehicle_type: "Sedan",
    fuelType: "Diesel",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan001",
    fuel_type: "Diesel",
    driver_id: "usr_Alice5678JohnABC",
    vehicle_identifier: "XTR56789Z",
    driver: "Alice Johnson",
    qr_code: "QR987654321",
    iot_type: "Axons Micro",
    iot_name: "IOT_002",
    imei: "65432198",
    bluetooth_flow: "Yes",
    last_active: "Yesterday",
    lat: 23.02711620495836,
    lng: 72.59229038951551,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-8",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan001",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Catherine Lee",
    vehicle_qr: "Vehicle003",
    imei: "a23fd459df4bb67534",
    battery: "75",
    id: "usr_Abc9876ghiJKL4",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    alerts: [],
    fleet: "Satellite",
    status: "In Transit",
    flag: "Battery Critical",
    vehicle_type: "Hatchback",
    fleet: "Jackson Line Metro",
    fuelType: "Electric",
    vehicle_name: "Hatchback003",
    fuel_type: "Hybrid",
    driver_id: "usr_John7890DoeXYZ",
    vehicle_identifier: "CAR09876Q",
    driver: "John Doe",
    qr_code: "QR654321123",
    iot_type: "Axons Micro",
    iot_name: "IOT_003",
    imei: "78912345",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.02677323479584,
    lng:72.5895786896011,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback003",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "David Clark",
    vehicle_qr: "Vehicle004",
    imei: "f45eab78ca2d5c7834",
    battery: "60",
    id: "usr_Jkl5432mnoPQR5",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: [],
    fleet: "Shyamal",
    status: "Idle",
    flag: "IOT Fault",
    vehicle_type: "SUV",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TRK43210P",
    fuelType: "Electric",
    vehicle_name: "SUV004",
    fuel_type: "Gasoline",
    driver_id: "usr_Sarah2345WillDEF",
    driver: "Sarah Wilson",
    qr_code: "QR123123123",
    iot_type: "Axons Micro Pro",
    iot_name: "IOT_004",
    imei: "98765432",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.02720859185736,
    lng: 72.5884483983532,

    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV004",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Sarah Wilson",
    vehicle_qr: "Vehicle005",
    imei: "c67bfa789bcd1d3542",
    battery: "50",
    id: "usr_Qrs1290pqrSTU6",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Maninagar",
    vehicle_identifier: "MTR23456H",
    status: "Idle",
    flag: "IOT Fault",
    fuelType: "Diesel",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan005",
    fuel_type: "Electric",
    driver_id: "usr_James4567BrownXYZ",
    driver: "James Brown",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_005",
    imei: "98765432",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.02643617139715,
    lng: 72.58689676703338,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan005",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Franklin Perez",
    vehicle_qr: "Vehicle006",
    imei: "g23acd579e2b3f6745",
    battery: "95",
    id: "usr_Wxy6789stuVWX7",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "SUV87654L",
    alerts: [],
    status: "Idle",
    flag: "",
    vehicle_type: "Cybertruck",
    fleet: "Martin Urban Transit",
    vehicle_name: "Cybertruck006",
    fuel_type: "Electric",
    fuelType: "Diesel",
    driver_id: "usr_Emily6789DavisDEF",
    driver: "Sarah Wilson",
    qr_code: "QR345678901",
    iot_type: "Axons Micro",
    iot_name: "IOT_006",
    imei: "65432198",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.025766146685825,
    lng: 72.58759591090768,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Cybertruck006",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Grace Thompson",
    vehicle_qr: "Vehicle007",
    imei: "h56efg790b4e2d8794",
    battery: "70",
    id: "usr_Uvw4321xyzABC8",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "BUS12390F",
    status: "In Transit",
    fuelType: "Diesel",
    alerts: ["Service Due"],
    alerts_description: "Scheduled maintenance or service is due soon.",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan007",
    fuel_type: "Diesel",
    driver_id: "usr_Mike1234JonesXYZ",
    driver: "Mike Jones",
    qr_code: "QR789456123",
    lat: 23.026796864568468,
    lng: 72.58761623754191,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan007",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Henry Walker",
    vehicle_qr: "Vehicle008",
    imei: "i78hij890d5f2b9803",
    battery: "40",
    id: "usr_Zab0987defDEF9",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "VAN54321E",
    status: "In Transit",
    fuelType: "Petrol",
    alerts: [],
    alerts_description: "",
    vehicle_type: "SUV",
    fleet: "Maninagar Fleet",
    vehicle_name: "SUV008",
    fuel_type: "Diesel",
    driver_id: "usr_Linda5678ClarkABC",
    driver: "Linda Clark",
    qr_code: "QR567890123",
    iot_type: "Axons Micro",
    iot_name: "IOT_008",
    imei: "32145698",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.02579634280437,
    lng: 72.59179558680984,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV008",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Isabella Martinez",
    vehicle_qr: "Vehicle009",
    imei: "j12klm891c6g3h0912",
    battery: "80",
    id: "usr_Ghi8765jklGHI0",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    status: "In Transit",
    alerts: [],
    alerts_description:
      "Payment deadline to maintain active insurance coverage.",
    vehicle_type: "Hatchback",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TMP65748R",
    vehicle_name: "Hatchback009",
    fuelType: "Electric",
    fuel_type: "Hybrid",
    driver_id: "usr_Robert7890LeeXYZ",
    driver: "Robert Lee",
    qr_code: "QR654789321",
    iot_type: "Axons Micro",
    iot_name: "IOT_009",
    imei: "78932156",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.026030351896836,
    lng: 72.59337984861432,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback009",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Jack White",
    vehicle_qr: "Vehicle010",
    imei: "k34nop892f7j4i1201",
    battery: "65",
    id: "usr_Nop5432klmNOP1",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Shyamal",
    vehicle_identifier: "AUT31245W",
    status: "In Transit",
    flag: "",
    vehicle_type: "Sedan",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan010",
    fuelType: "Petrol",
    fuel_type: "Electric",
    driver_id: "usr_Nancy2345HillDEF",
    alerts: [],
    driver: "Nancy Hill",
    qr_code: "QR123098765",
    iot_type: "Axons Micro",
    iot_name: "IOT_010",
    imei: "65478923",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.024859646712983,
    lng: 72.58765243474306,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan010",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ]
  },

  //31-40
  {
    user_name: "Alice Johnson",
    vehicle_qr: "Vehicle001",
    imei: "e00fce68a0ad7b4549",
    battery: "90",
    id: "usr_Xyz1234abcDEF2",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fuelType: "Petrol",
    status: "Idle",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    vehicle_type: "Cybertruck",
    fleet: "King Street Transit",
    vehicle_name: "GenCar2",
    fuel_type: "Electric",
    driver_id: "usr_Bob1234SmithXYZ",
    vehicle_identifier: "VEH12345X",
    driver: "Bob Smith",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_001",
    imei: "12345697",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.02656876166229,
    lng: 72.58502097587413,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "GenCar2",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Bob Smith",
    vehicle_qr: "Vehicle002",
    imei: "d35bfc78e9bc8d4453",
    battery: "85",
    id: "usr_Yty4567defGHI3",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: ["Service Due", "Insurance Due"],
    alerts_description:
      "Scheduled service and insurance renewals are due soon.",
    status: "In Transit",
    flag: "Iot fault",
    vehicle_type: "Sedan",
    fuelType: "Diesel",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan001",
    fuel_type: "Diesel",
    driver_id: "usr_Alice5678JohnABC",
    vehicle_identifier: "XTR56789Z",
    driver: "Alice Johnson",
    qr_code: "QR987654321",
    iot_type: "Axons Micro",
    iot_name: "IOT_002",
    imei: "65432198",
    bluetooth_flow: "Yes",
    last_active: "Yesterday",
    lat: 23.025692133619046,
    lng: 72.58517021958428,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-8",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan001",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Catherine Lee",
    vehicle_qr: "Vehicle003",
    imei: "a23fd459df4bb67534",
    battery: "75",
    id: "usr_Abc9876ghiJKL4",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    alerts: [],
    fleet: "Satellite",
    status: "In Transit",
    flag: "Battery Critical",
    vehicle_type: "Hatchback",
    fleet: "Jackson Line Metro",
    fuelType: "Electric",
    vehicle_name: "Hatchback003",
    fuel_type: "Hybrid",
    driver_id: "usr_John7890DoeXYZ",
    vehicle_identifier: "CAR09876Q",
    driver: "John Doe",
    qr_code: "QR654321123",
    iot_type: "Axons Micro",
    iot_name: "IOT_003",
    imei: "78912345",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.025426452160573,
    lng: 72.58328176524262,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback003",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "David Clark",
    vehicle_qr: "Vehicle004",
    imei: "f45eab78ca2d5c7834",
    battery: "60",
    id: "usr_Jkl5432mnoPQR5",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Unlock",
    alerts: [],
    fleet: "Shyamal",
    status: "Idle",
    flag: "IOT Fault",
    vehicle_type: "SUV",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TRK43210P",
    fuelType: "Electric",
    vehicle_name: "SUV004",
    fuel_type: "Gasoline",
    driver_id: "usr_Sarah2345WillDEF",
    driver: "Sarah Wilson",
    qr_code: "QR123123123",
    iot_type: "Axons Micro Pro",
    iot_name: "IOT_004",
    imei: "98765432",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.026572535575824,
    lng: 72.58385466669151,

    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV004",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Sarah Wilson",
    vehicle_qr: "Vehicle005",
    imei: "c67bfa789bcd1d3542",
    battery: "50",
    id: "usr_Qrs1290pqrSTU6",
    flags: iotflagicon,
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Maninagar",
    vehicle_identifier: "MTR23456H",
    status: "Idle",
    flag: "IOT Fault",
    fuelType: "Diesel",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan005",
    fuel_type: "Electric",
    driver_id: "usr_James4567BrownXYZ",
    driver: "James Brown",
    qr_code: "QR123456789",
    iot_type: "Axons Micro",
    iot_name: "IOT_005",
    imei: "98765432",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.02476856603657,
    lng: 72.58398429695282,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan005",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Franklin Perez",
    vehicle_qr: "Vehicle006",
    imei: "g23acd579e2b3f6745",
    battery: "95",
    id: "usr_Wxy6789stuVWX7",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "SUV87654L",
    alerts: [],
    status: "Idle",
    flag: "",
    vehicle_type: "Cybertruck",
    fleet: "Martin Urban Transit",
    vehicle_name: "Cybertruck006",
    fuel_type: "Electric",
    fuelType: "Diesel",
    driver_id: "usr_Emily6789DavisDEF",
    driver: "Sarah Wilson",
    qr_code: "QR345678901",
    iot_type: "Axons Micro",
    iot_name: "IOT_006",
    imei: "65432198",
    bluetooth_flow: "No",
    last_active: "Today",
    lat:  23.023908501049192,
    lng: 72.58493984704876,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Cybertruck006",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Grace Thompson",
    vehicle_qr: "Vehicle007",
    imei: "h56efg790b4e2d8794",
    battery: "70",
    id: "usr_Uvw4321xyzABC8",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    vehicle_identifier: "BUS12390F",
    status: "In Transit",
    fuelType: "Diesel",
    alerts: ["Service Due"],
    alerts_description: "Scheduled maintenance or service is due soon.",
    vehicle_type: "Sedan",
    fleet: "Norton Neighborhood Buses",
    vehicle_name: "Sedan007",
    fuel_type: "Diesel",
    driver_id: "usr_Mike1234JonesXYZ",
    driver: "Mike Jones",
    qr_code: "QR789456123",
    lat: 23.027493793185386,
    lng: 72.58439297658089,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan007",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Henry Walker",
    vehicle_qr: "Vehicle008",
    imei: "i78hij890d5f2b9803",
    battery: "40",
    id: "usr_Zab0987defDEF9",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "VAN54321E",
    status: "In Transit",
    fuelType: "Petrol",
    alerts: [],
    alerts_description: "",
    vehicle_type: "SUV",
    fleet: "Maninagar Fleet",
    vehicle_name: "SUV008",
    fuel_type: "Diesel",
    driver_id: "usr_Linda5678ClarkABC",
    driver: "Linda Clark",
    qr_code: "QR567890123",
    iot_type: "Axons Micro",
    iot_name: "IOT_008",
    imei: "32145698",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.025882818747604,
    lng: 72.58271991821022,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "SUV008",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Isabella Martinez",
    vehicle_qr: "Vehicle009",
    imei: "j12klm891c6g3h0912",
    battery: "80",
    id: "usr_Ghi8765jklGHI0",
    flags: "",
    location: mapicon,
    vehicle_action: "Lock",
    fleet: "Jackson Line Metro",
    status: "In Transit",
    alerts: [],
    alerts_description:
      "Payment deadline to maintain active insurance coverage.",
    vehicle_type: "Hatchback",
    fleet: "Martin Urban Transit",
    vehicle_identifier: "TMP65748R",
    vehicle_name: "Hatchback009",
    fuelType: "Electric",
    fuel_type: "Hybrid",
    driver_id: "usr_Robert7890LeeXYZ",
    driver: "Robert Lee",
    qr_code: "QR654789321",
    iot_type: "Axons Micro",
    iot_name: "IOT_009",
    imei: "78932156",
    bluetooth_flow: "No",
    last_active: "Today",
    lat: 23.024132173542483,
    lng: 72.58256907079615,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Hatchback009",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ],
  },
  {
    user_name: "Jack White",
    vehicle_qr: "Vehicle010",
    imei: "k34nop892f7j4i1201",
    battery: "65",
    id: "usr_Nop5432klmNOP1",
    flags: "",
    location: mapicon,
    vehicle_action: "Unlock",
    fleet: "Shyamal",
    vehicle_identifier: "AUT31245W",
    status: "In Transit",
    flag: "",
    vehicle_type: "Sedan",
    fleet: "Evans City Shuttle",
    vehicle_name: "Sedan010",
    fuelType: "Petrol",
    fuel_type: "Electric",
    driver_id: "usr_Nancy2345HillDEF",
    alerts: [],
    driver: "Nancy Hill",
    qr_code: "QR123098765",
    iot_type: "Axons Micro",
    iot_name: "IOT_010",
    imei: "65478923",
    bluetooth_flow: "Yes",
    last_active: "Today",
    lat: 23.023647739267588,
    lng: 72.58324967061631,
    trip_details: [
      {
        trip_id: "trip-1",
        driver: "Bob Smith",
        driver_id: "usr_Bob1234SmithXYZ",
        start_date_time: "Sep 13th, 2024 18:30:44",
        end_date_time: "Sep 13th, 2024 19:43:44",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-2",
        driver: "Alice Johnson",
        driver_id: "usr_Alice5678JohnABC",
        start_date_time: "Sep 14th, 2024 09:15:32",
        end_date_time: "Sep 14th, 2024 10:55:21",
        duration: "1h 40min",
      },
      {
        trip_id: "trip-3",
        driver: "John Doe",
        driver_id: "usr_John7890DoeXYZ",
        start_date_time: "Sep 15th, 2024 14:20:12",
        end_date_time: "Sep 15th, 2024 15:45:33",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-4",
        driver: "Sarah Wilson",
        driver_id: "usr_Sarah2345WillDEF",
        start_date_time: "Sep 16th, 2024 12:00:00",
        end_date_time: "Sep 16th, 2024 13:30:10",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-6",
        driver: "James Brown",
        driver_id: "usr_James4567BrownXYZ",
        start_date_time: "Sep 18th, 2024 17:25:19",
        end_date_time: "Sep 18th, 2024 18:50:42",
        duration: "1h 25min",
      },
      {
        trip_id: "trip-7",
        driver: "Emily Davis",
        driver_id: "usr_Emily6789DavisDEF",
        start_date_time: "Sep 19th, 2024 13:10:30",
        end_date_time: "Sep 19th, 2024 14:40:58",
        duration: "1h 30min",
      },
      {
        trip_id: "trip-5",
        driver: "Mike Jones",
        driver_id: "usr_Mike1234JonesXYZ",
        start_date_time: "Sep 17th, 2024 08:45:05",
        end_date_time: "Sep 17th, 2024 10:15:55",
        duration: "1h 30min",
      },
    ],
    timeline_heading: "Sedan010",
    timeline_description:
      "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
    timeline_details: [
      {
        id: "timeline_5",
        title: "Vehicle Scheduled for Maintenance",
        description: "Scheduled the vehicle for routine maintenance.",
        date: "May 26, 2024",
      },
      {
        id: "timeline_4",
        title: "IoT Settings Optimized",
        description: "Updated and optimized IoT settings.",
        date: "May 23, 2024",
      },
      {
        id: "timeline_3",
        title: "Vehicle Inspection Completed",
        description: "Completed the vehicle inspection.",
        date: "May 22, 2024",
      },
      {
        id: "timeline_2",
        title: "IoT System Installed",
        description: "Installed IoT system in the vehicle.",
        date: "May 21, 2024",
      },
      {
        id: "timeline_1",
        title: "New Vehicle Assigned",
        description: "Assigned a new vehicle to Chris Evans.",
        date: "May 18, 2024",
      },
    ]
  },

    //41-50
    {
      user_name: "Alice Johnson",
      vehicle_qr: "Vehicle001",
      imei: "e00fce68a0ad7b4549",
      battery: "90",
      id: "usr_Xyz1234abcDEF2",
      flags: iotflagicon,
      location: mapicon,
      vehicle_action: "Lock",
      fuelType: "Petrol",
      status: "Idle",
      alerts: ["Service Due", "Insurance Due"],
      alerts_description:
        "Scheduled service and insurance renewals are due soon.",
      vehicle_type: "Cybertruck",
      fleet: "King Street Transit",
      vehicle_name: "GenCar2",
      fuel_type: "Electric",
      driver_id: "usr_Bob1234SmithXYZ",
      vehicle_identifier: "VEH12345X",
      driver: "Bob Smith",
      qr_code: "QR123456789",
      iot_type: "Axons Micro",
      iot_name: "IOT_001",
      imei: "12345697",
      bluetooth_flow: "No",
      last_active: "Today",
      lat: 23.023342506741088,
      lng: 72.58760893184387,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "GenCar2",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Bob Smith",
      vehicle_qr: "Vehicle002",
      imei: "d35bfc78e9bc8d4453",
      battery: "85",
      id: "usr_Yty4567defGHI3",
      flags: iotflagicon,
      location: mapicon,
      vehicle_action: "Unlock",
      alerts: ["Service Due", "Insurance Due"],
      alerts_description:
        "Scheduled service and insurance renewals are due soon.",
      status: "In Transit",
      flag: "Iot fault",
      vehicle_type: "Sedan",
      fuelType: "Diesel",
      fleet: "Evans City Shuttle",
      vehicle_name: "Sedan001",
      fuel_type: "Diesel",
      driver_id: "usr_Alice5678JohnABC",
      vehicle_identifier: "XTR56789Z",
      driver: "Alice Johnson",
      qr_code: "QR987654321",
      iot_type: "Axons Micro",
      iot_name: "IOT_002",
      imei: "65432198",
      bluetooth_flow: "Yes",
      last_active: "Yesterday",
      lat: 23.023356127862172,
      lng: 72.5889207399185,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-8",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Sedan001",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Catherine Lee",
      vehicle_qr: "Vehicle003",
      imei: "a23fd459df4bb67534",
      battery: "75",
      id: "usr_Abc9876ghiJKL4",
      flags: "",
      location: mapicon,
      vehicle_action: "Lock",
      alerts: [],
      fleet: "Satellite",
      status: "In Transit",
      flag: "Battery Critical",
      vehicle_type: "Hatchback",
      fleet: "Jackson Line Metro",
      fuelType: "Electric",
      vehicle_name: "Hatchback003",
      fuel_type: "Hybrid",
      driver_id: "usr_John7890DoeXYZ",
      vehicle_identifier: "CAR09876Q",
      driver: "John Doe",
      qr_code: "QR654321123",
      iot_type: "Axons Micro",
      iot_name: "IOT_003",
      imei: "78912345",
      bluetooth_flow: "Yes",
      last_active: "Today",
      lat: 23.028155605570987,
      lng: 72.58717956550285,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Hatchback003",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "David Clark",
      vehicle_qr: "Vehicle004",
      imei: "f45eab78ca2d5c7834",
      battery: "60",
      id: "usr_Jkl5432mnoPQR5",
      flags: iotflagicon,
      location: mapicon,
      vehicle_action: "Unlock",
      alerts: [],
      fleet: "Shyamal",
      status: "Idle",
      flag: "IOT Fault",
      vehicle_type: "SUV",
      fleet: "Martin Urban Transit",
      vehicle_identifier: "TRK43210P",
      fuelType: "Electric",
      vehicle_name: "SUV004",
      fuel_type: "Gasoline",
      driver_id: "usr_Sarah2345WillDEF",
      driver: "Sarah Wilson",
      qr_code: "QR123123123",
      iot_type: "Axons Micro Pro",
      iot_name: "IOT_004",
      imei: "98765432",
      bluetooth_flow: "No",
      last_active: "Today",
      lat: 23.02324174603207,
      lng: 72.58611810310109,
  
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "SUV004",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Sarah Wilson",
      vehicle_qr: "Vehicle005",
      imei: "c67bfa789bcd1d3542",
      battery: "50",
      id: "usr_Qrs1290pqrSTU6",
      flags: iotflagicon,
      location: mapicon,
      vehicle_action: "Lock",
      fleet: "Maninagar",
      vehicle_identifier: "MTR23456H",
      status: "Idle",
      flag: "IOT Fault",
      fuelType: "Diesel",
      vehicle_type: "Sedan",
      fleet: "Norton Neighborhood Buses",
      vehicle_name: "Sedan005",
      fuel_type: "Electric",
      driver_id: "usr_James4567BrownXYZ",
      driver: "James Brown",
      qr_code: "QR123456789",
      iot_type: "Axons Micro",
      iot_name: "IOT_005",
      imei: "98765432",
      bluetooth_flow: "Yes",
      last_active: "Today",
      lat: 23.02834661574903,
      lng: 72.58571387799716,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Sedan005",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Franklin Perez",
      vehicle_qr: "Vehicle006",
      imei: "g23acd579e2b3f6745",
      battery: "95",
      id: "usr_Wxy6789stuVWX7",
      flags: "",
      location: mapicon,
      vehicle_action: "Unlock",
      fleet: "Jackson Line Metro",
      vehicle_identifier: "SUV87654L",
      alerts: [],
      status: "Idle",
      flag: "",
      vehicle_type: "Cybertruck",
      fleet: "Martin Urban Transit",
      vehicle_name: "Cybertruck006",
      fuel_type: "Electric",
      fuelType: "Diesel",
      driver_id: "usr_Emily6789DavisDEF",
      driver: "Sarah Wilson",
      qr_code: "QR345678901",
      iot_type: "Axons Micro",
      iot_name: "IOT_006",
      imei: "65432198",
      bluetooth_flow: "No",
      last_active: "Today",
      lat: 23.024697922223083,
      lng: 72.5911305038947,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Cybertruck006",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Grace Thompson",
      vehicle_qr: "Vehicle007",
      imei: "h56efg790b4e2d8794",
      battery: "70",
      id: "usr_Uvw4321xyzABC8",
      flags: "",
      location: mapicon,
      vehicle_action: "Lock",
      fleet: "Jackson Line Metro",
      vehicle_identifier: "BUS12390F",
      status: "In Transit",
      fuelType: "Diesel",
      alerts: ["Service Due"],
      alerts_description: "Scheduled maintenance or service is due soon.",
      vehicle_type: "Sedan",
      fleet: "Norton Neighborhood Buses",
      vehicle_name: "Sedan007",
      fuel_type: "Diesel",
      driver_id: "usr_Mike1234JonesXYZ",
      driver: "Mike Jones",
      qr_code: "QR789456123",
      lat: 23.02502202414054,
      lng: 72.58874555215564,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Sedan007",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Henry Walker",
      vehicle_qr: "Vehicle008",
      imei: "i78hij890d5f2b9803",
      battery: "40",
      id: "usr_Zab0987defDEF9",
      flags: "",
      location: mapicon,
      vehicle_action: "Unlock",
      fleet: "Martin Urban Transit",
      vehicle_identifier: "VAN54321E",
      status: "In Transit",
      fuelType: "Petrol",
      alerts: [],
      alerts_description: "",
      vehicle_type: "SUV",
      fleet: "Maninagar Fleet",
      vehicle_name: "SUV008",
      fuel_type: "Diesel",
      driver_id: "usr_Linda5678ClarkABC",
      driver: "Linda Clark",
      qr_code: "QR567890123",
      iot_type: "Axons Micro",
      iot_name: "IOT_008",
      imei: "32145698",
      bluetooth_flow: "Yes",
      last_active: "Today",
      lat: 23.024497716928252,
      lng: 72.59208730187552,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "SUV008",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Isabella Martinez",
      vehicle_qr: "Vehicle009",
      imei: "j12klm891c6g3h0912",
      battery: "80",
      id: "usr_Ghi8765jklGHI0",
      flags: "",
      location: mapicon,
      vehicle_action: "Lock",
      fleet: "Jackson Line Metro",
      status: "In Transit",
      alerts: [],
      alerts_description:
        "Payment deadline to maintain active insurance coverage.",
      vehicle_type: "Hatchback",
      fleet: "Martin Urban Transit",
      vehicle_identifier: "TMP65748R",
      vehicle_name: "Hatchback009",
      fuelType: "Electric",
      fuel_type: "Hybrid",
      driver_id: "usr_Robert7890LeeXYZ",
      driver: "Robert Lee",
      qr_code: "QR654789321",
      iot_type: "Axons Micro",
      iot_name: "IOT_009",
      imei: "78932156",
      bluetooth_flow: "No",
      last_active: "Today",
      lat: 23.025510720588343,
      lng: 72.58941634467274,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Hatchback009",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ],
    },
    {
      user_name: "Jack White",
      vehicle_qr: "Vehicle010",
      imei: "k34nop892f7j4i1201",
      battery: "65",
      id: "usr_Nop5432klmNOP1",
      flags: "",
      location: mapicon,
      vehicle_action: "Unlock",
      fleet: "Shyamal",
      vehicle_identifier: "AUT31245W",
      status: "In Transit",
      flag: "",
      vehicle_type: "Sedan",
      fleet: "Evans City Shuttle",
      vehicle_name: "Sedan010",
      fuelType: "Petrol",
      fuel_type: "Electric",
      driver_id: "usr_Nancy2345HillDEF",
      alerts: [],
      driver: "Nancy Hill",
      qr_code: "QR123098765",
      iot_type: "Axons Micro",
      iot_name: "IOT_010",
      imei: "65478923",
      bluetooth_flow: "Yes",
      last_active: "Today",
      lat: 22.960269,
      lng: 72.62067,
      trip_details: [
        {
          trip_id: "trip-1",
          driver: "Bob Smith",
          driver_id: "usr_Bob1234SmithXYZ",
          start_date_time: "Sep 13th, 2024 18:30:44",
          end_date_time: "Sep 13th, 2024 19:43:44",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-2",
          driver: "Alice Johnson",
          driver_id: "usr_Alice5678JohnABC",
          start_date_time: "Sep 14th, 2024 09:15:32",
          end_date_time: "Sep 14th, 2024 10:55:21",
          duration: "1h 40min",
        },
        {
          trip_id: "trip-3",
          driver: "John Doe",
          driver_id: "usr_John7890DoeXYZ",
          start_date_time: "Sep 15th, 2024 14:20:12",
          end_date_time: "Sep 15th, 2024 15:45:33",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-4",
          driver: "Sarah Wilson",
          driver_id: "usr_Sarah2345WillDEF",
          start_date_time: "Sep 16th, 2024 12:00:00",
          end_date_time: "Sep 16th, 2024 13:30:10",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-6",
          driver: "James Brown",
          driver_id: "usr_James4567BrownXYZ",
          start_date_time: "Sep 18th, 2024 17:25:19",
          end_date_time: "Sep 18th, 2024 18:50:42",
          duration: "1h 25min",
        },
        {
          trip_id: "trip-7",
          driver: "Emily Davis",
          driver_id: "usr_Emily6789DavisDEF",
          start_date_time: "Sep 19th, 2024 13:10:30",
          end_date_time: "Sep 19th, 2024 14:40:58",
          duration: "1h 30min",
        },
        {
          trip_id: "trip-5",
          driver: "Mike Jones",
          driver_id: "usr_Mike1234JonesXYZ",
          start_date_time: "Sep 17th, 2024 08:45:05",
          end_date_time: "Sep 17th, 2024 10:15:55",
          duration: "1h 30min",
        },
      ],
      timeline_heading: "Sedan010",
      timeline_description:
        "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
      timeline_details: [
        {
          id: "timeline_5",
          title: "Vehicle Scheduled for Maintenance",
          description: "Scheduled the vehicle for routine maintenance.",
          date: "May 26, 2024",
        },
        {
          id: "timeline_4",
          title: "IoT Settings Optimized",
          description: "Updated and optimized IoT settings.",
          date: "May 23, 2024",
        },
        {
          id: "timeline_3",
          title: "Vehicle Inspection Completed",
          description: "Completed the vehicle inspection.",
          date: "May 22, 2024",
        },
        {
          id: "timeline_2",
          title: "IoT System Installed",
          description: "Installed IoT system in the vehicle.",
          date: "May 21, 2024",
        },
        {
          id: "timeline_1",
          title: "New Vehicle Assigned",
          description: "Assigned a new vehicle to Chris Evans.",
          date: "May 18, 2024",
        },
      ]
    },

       //51-60
       {
        user_name: "Alice Johnson",
        vehicle_qr: "Vehicle001",
        imei: "e00fce68a0ad7b4549",
        battery: "90",
        id: "usr_Xyz1234abcDEF2",
        flags: iotflagicon,
        location: mapicon,
        vehicle_action: "Lock",
        fuelType: "Petrol",
        status: "Idle",
        alerts: ["Service Due", "Insurance Due"],
        alerts_description:
          "Scheduled service and insurance renewals are due soon.",
        vehicle_type: "Cybertruck",
        fleet: "King Street Transit",
        vehicle_name: "GenCar2",
        fuel_type: "Electric",
        driver_id: "usr_Bob1234SmithXYZ",
        vehicle_identifier: "VEH12345X",
        driver: "Bob Smith",
        qr_code: "QR123456789",
        iot_type: "Axons Micro",
        iot_name: "IOT_001",
        imei: "12345697",
        bluetooth_flow: "No",
        last_active: "Today",
        lat: 23.037269,
        lng: 72.556233    ,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "GenCar2",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Bob Smith",
        vehicle_qr: "Vehicle002",
        imei: "d35bfc78e9bc8d4453",
        battery: "85",
        id: "usr_Yty4567defGHI3",
        flags: iotflagicon,
        location: mapicon,
        vehicle_action: "Unlock",
        alerts: ["Service Due", "Insurance Due"],
        alerts_description:
          "Scheduled service and insurance renewals are due soon.",
        status: "In Transit",
        flag: "Iot fault",
        vehicle_type: "Sedan",
        fuelType: "Diesel",
        fleet: "Evans City Shuttle",
        vehicle_name: "Sedan001",
        fuel_type: "Diesel",
        driver_id: "usr_Alice5678JohnABC",
        vehicle_identifier: "XTR56789Z",
        driver: "Alice Johnson",
        qr_code: "QR987654321",
        iot_type: "Axons Micro",
        iot_name: "IOT_002",
        imei: "65432198",
        bluetooth_flow: "Yes",
        last_active: "Yesterday",
        lat: 23.038269,
        lng: 72.556233,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-8",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Sedan001",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Catherine Lee",
        vehicle_qr: "Vehicle003",
        imei: "a23fd459df4bb67534",
        battery: "75",
        id: "usr_Abc9876ghiJKL4",
        flags: "",
        location: mapicon,
        vehicle_action: "Lock",
        alerts: [],
        fleet: "Satellite",
        status: "In Transit",
        flag: "Battery Critical",
        vehicle_type: "Hatchback",
        fleet: "Jackson Line Metro",
        fuelType: "Electric",
        vehicle_name: "Hatchback003",
        fuel_type: "Hybrid",
        driver_id: "usr_John7890DoeXYZ",
        vehicle_identifier: "CAR09876Q",
        driver: "John Doe",
        qr_code: "QR654321123",
        iot_type: "Axons Micro",
        iot_name: "IOT_003",
        imei: "78912345",
        bluetooth_flow: "Yes",
        last_active: "Today",
        lat: 23.036269,
        lng: 72.556233,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Hatchback003",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "David Clark",
        vehicle_qr: "Vehicle004",
        imei: "f45eab78ca2d5c7834",
        battery: "60",
        id: "usr_Jkl5432mnoPQR5",
        flags: iotflagicon,
        location: mapicon,
        vehicle_action: "Unlock",
        alerts: [],
        fleet: "Shyamal",
        status: "Idle",
        flag: "IOT Fault",
        vehicle_type: "SUV",
        fleet: "Martin Urban Transit",
        vehicle_identifier: "TRK43210P",
        fuelType: "Electric",
        vehicle_name: "SUV004",
        fuel_type: "Gasoline",
        driver_id: "usr_Sarah2345WillDEF",
        driver: "Sarah Wilson",
        qr_code: "QR123123123",
        iot_type: "Axons Micro Pro",
        iot_name: "IOT_004",
        imei: "98765432",
        bluetooth_flow: "No",
        last_active: "Today",
        lat: 23.037269,
        lng: 72.556233,
    
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "SUV004",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Sarah Wilson",
        vehicle_qr: "Vehicle005",
        imei: "c67bfa789bcd1d3542",
        battery: "50",
        id: "usr_Qrs1290pqrSTU6",
        flags: iotflagicon,
        location: mapicon,
        vehicle_action: "Lock",
        fleet: "Maninagar",
        vehicle_identifier: "MTR23456H",
        status: "Idle",
        flag: "IOT Fault",
        fuelType: "Diesel",
        vehicle_type: "Sedan",
        fleet: "Norton Neighborhood Buses",
        vehicle_name: "Sedan005",
        fuel_type: "Electric",
        driver_id: "usr_James4567BrownXYZ",
        driver: "James Brown",
        qr_code: "QR123456789",
        iot_type: "Axons Micro",
        iot_name: "IOT_005",
        imei: "98765432",
        bluetooth_flow: "Yes",
        last_active: "Today",
        lat: 23.026803,
        lng: 72.619063,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Sedan005",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Franklin Perez",
        vehicle_qr: "Vehicle006",
        imei: "g23acd579e2b3f6745",
        battery: "95",
        id: "usr_Wxy6789stuVWX7",
        flags: "",
        location: mapicon,
        vehicle_action: "Unlock",
        fleet: "Jackson Line Metro",
        vehicle_identifier: "SUV87654L",
        alerts: [],
        status: "Idle",
        flag: "",
        vehicle_type: "Cybertruck",
        fleet: "Martin Urban Transit",
        vehicle_name: "Cybertruck006",
        fuel_type: "Electric",
        fuelType: "Diesel",
        driver_id: "usr_Emily6789DavisDEF",
        driver: "Sarah Wilson",
        qr_code: "QR345678901",
        iot_type: "Axons Micro",
        iot_name: "IOT_006",
        imei: "65432198",
        bluetooth_flow: "No",
        last_active: "Today",
        lat: 23.041675,
        lng: 72.643038,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Cybertruck006",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Grace Thompson",
        vehicle_qr: "Vehicle007",
        imei: "h56efg790b4e2d8794",
        battery: "70",
        id: "usr_Uvw4321xyzABC8",
        flags: "",
        location: mapicon,
        vehicle_action: "Lock",
        fleet: "Jackson Line Metro",
        vehicle_identifier: "BUS12390F",
        status: "In Transit",
        fuelType: "Diesel",
        alerts: ["Service Due"],
        alerts_description: "Scheduled maintenance or service is due soon.",
        vehicle_type: "Sedan",
        fleet: "Norton Neighborhood Buses",
        vehicle_name: "Sedan007",
        fuel_type: "Diesel",
        driver_id: "usr_Mike1234JonesXYZ",
        driver: "Mike Jones",
        qr_code: "QR789456123",
        lat: 22.963306,
        lng: 72.494798,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Sedan007",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Henry Walker",
        vehicle_qr: "Vehicle008",
        imei: "i78hij890d5f2b9803",
        battery: "40",
        id: "usr_Zab0987defDEF9",
        flags: "",
        location: mapicon,
        vehicle_action: "Unlock",
        fleet: "Martin Urban Transit",
        vehicle_identifier: "VAN54321E",
        status: "In Transit",
        fuelType: "Petrol",
        alerts: [],
        alerts_description: "",
        vehicle_type: "SUV",
        fleet: "Maninagar Fleet",
        vehicle_name: "SUV008",
        fuel_type: "Diesel",
        driver_id: "usr_Linda5678ClarkABC",
        driver: "Linda Clark",
        qr_code: "QR567890123",
        iot_type: "Axons Micro",
        iot_name: "IOT_008",
        imei: "32145698",
        bluetooth_flow: "Yes",
        last_active: "Today",
        lat: 23.018496,
        lng: 72.577618,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "SUV008",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Isabella Martinez",
        vehicle_qr: "Vehicle009",
        imei: "j12klm891c6g3h0912",
        battery: "80",
        id: "usr_Ghi8765jklGHI0",
        flags: "",
        location: mapicon,
        vehicle_action: "Lock",
        fleet: "Jackson Line Metro",
        status: "In Transit",
        alerts: [],
        alerts_description:
          "Payment deadline to maintain active insurance coverage.",
        vehicle_type: "Hatchback",
        fleet: "Martin Urban Transit",
        vehicle_identifier: "TMP65748R",
        vehicle_name: "Hatchback009",
        fuelType: "Electric",
        fuel_type: "Hybrid",
        driver_id: "usr_Robert7890LeeXYZ",
        driver: "Robert Lee",
        qr_code: "QR654789321",
        iot_type: "Axons Micro",
        iot_name: "IOT_009",
        imei: "78932156",
        bluetooth_flow: "No",
        last_active: "Today",
        lat: 22.96067,
        lng: 72.515426,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Hatchback009",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ],
      },
      {
        user_name: "Jack White",
        vehicle_qr: "Vehicle010",
        imei: "k34nop892f7j4i1201",
        battery: "65",
        id: "usr_Nop5432klmNOP1",
        flags: "",
        location: mapicon,
        vehicle_action: "Unlock",
        fleet: "Shyamal",
        vehicle_identifier: "AUT31245W",
        status: "In Transit",
        flag: "",
        vehicle_type: "Sedan",
        fleet: "Evans City Shuttle",
        vehicle_name: "Sedan010",
        fuelType: "Petrol",
        fuel_type: "Electric",
        driver_id: "usr_Nancy2345HillDEF",
        alerts: [],
        driver: "Nancy Hill",
        qr_code: "QR123098765",
        iot_type: "Axons Micro",
        iot_name: "IOT_010",
        imei: "65478923",
        bluetooth_flow: "Yes",
        last_active: "Today",
        lat: 22.960269,
        lng: 72.62067,
        trip_details: [
          {
            trip_id: "trip-1",
            driver: "Bob Smith",
            driver_id: "usr_Bob1234SmithXYZ",
            start_date_time: "Sep 13th, 2024 18:30:44",
            end_date_time: "Sep 13th, 2024 19:43:44",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-2",
            driver: "Alice Johnson",
            driver_id: "usr_Alice5678JohnABC",
            start_date_time: "Sep 14th, 2024 09:15:32",
            end_date_time: "Sep 14th, 2024 10:55:21",
            duration: "1h 40min",
          },
          {
            trip_id: "trip-3",
            driver: "John Doe",
            driver_id: "usr_John7890DoeXYZ",
            start_date_time: "Sep 15th, 2024 14:20:12",
            end_date_time: "Sep 15th, 2024 15:45:33",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-4",
            driver: "Sarah Wilson",
            driver_id: "usr_Sarah2345WillDEF",
            start_date_time: "Sep 16th, 2024 12:00:00",
            end_date_time: "Sep 16th, 2024 13:30:10",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-6",
            driver: "James Brown",
            driver_id: "usr_James4567BrownXYZ",
            start_date_time: "Sep 18th, 2024 17:25:19",
            end_date_time: "Sep 18th, 2024 18:50:42",
            duration: "1h 25min",
          },
          {
            trip_id: "trip-7",
            driver: "Emily Davis",
            driver_id: "usr_Emily6789DavisDEF",
            start_date_time: "Sep 19th, 2024 13:10:30",
            end_date_time: "Sep 19th, 2024 14:40:58",
            duration: "1h 30min",
          },
          {
            trip_id: "trip-5",
            driver: "Mike Jones",
            driver_id: "usr_Mike1234JonesXYZ",
            start_date_time: "Sep 17th, 2024 08:45:05",
            end_date_time: "Sep 17th, 2024 10:15:55",
            duration: "1h 30min",
          },
        ],
        timeline_heading: "Sedan010",
        timeline_description:
          "manages vehicle and IoT assets, ensuring timely additions and updates to the system, improving operational efficiency and seamless service integration.",
        timeline_details: [
          {
            id: "timeline_5",
            title: "Vehicle Scheduled for Maintenance",
            description: "Scheduled the vehicle for routine maintenance.",
            date: "May 26, 2024",
          },
          {
            id: "timeline_4",
            title: "IoT Settings Optimized",
            description: "Updated and optimized IoT settings.",
            date: "May 23, 2024",
          },
          {
            id: "timeline_3",
            title: "Vehicle Inspection Completed",
            description: "Completed the vehicle inspection.",
            date: "May 22, 2024",
          },
          {
            id: "timeline_2",
            title: "IoT System Installed",
            description: "Installed IoT system in the vehicle.",
            date: "May 21, 2024",
          },
          {
            id: "timeline_1",
            title: "New Vehicle Assigned",
            description: "Assigned a new vehicle to Chris Evans.",
            date: "May 18, 2024",
          },
        ]
      },
]
