import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  IconButton,
  useTheme,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import { MapComponent } from "../../components/MapComponent";
import IOSSwitch from "../../components/Switch";
import crossicon from "../../assets/Icons/drawercross.svg";
import CustomTextField from "../../components/MainTextField/CustomTextField";
import FilledButton from "../../components/MainButtons/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { addGeofenceData } from "../../slices/mapManageSlice";
import { useSnackbar } from "../../hooks/Snackbar";
import actionedit from "../../assets/Icons/actionedit.svg";
import actiondelete from "../../assets/Icons/actiondelete.svg";

const inputLabel = {
  fontSize: "0.8rem",
  fontWeight: 700,
  mb: 1,
  color: "#283246",
};

const InputFieldWrapper = ({
  label,
  name,
  value,
  onChange,
  placeholder,
  error,
  editable = true,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <CustomTextField
        value={value}
        onChange={onChange}
        name={name}
        error={!!error}
        sx={{
          "& .MuiOutlinedInput-root": {
            width: {
              xs: "100px",
              sm: "300px",
              md: "400px",
              lg: "300px",
              xl: "400px",
            },
          },
          input: { fontSize: "0.9rem" },
        }}
        disabled={!editable}
        placeholder={placeholder}
      />
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const CustomSelect = ({
  label,
  value,
  onChange,
  options,
  name,
  placeholder,
  error,
  editable = true,
}) => {
  return (
    <Box>
      <InputLabel sx={inputLabel}>
        {label} <span style={{ color: "#f99494", fontSize: "1rem" }}>*</span>
      </InputLabel>
      <Select
        value={value}
        onChange={onChange}
        sx={{
          borderRadius: "10px",
          width: {
            xs: "100px",
            sm: "300px",
            md: "400px",
            lg: "300px",
            xl: "400px",
          },
          height: "40px",
          fontSize: "0.9rem",
        }}
        name={name}
        error={!!error}
        disabled={!editable}
        displayEmpty
      >
        <MenuItem value="">
          <Typography color="text.primary">{placeholder}</Typography>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && (
        <FormHelperText sx={{ color: "red", fontSize: "0.8rem", ml: 1 }}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

const GeofenceDrawer = ({
  drawerOpen,
  setDrawerOpen,
  setEditGeofence,
  editGeofence,
  handleDelete
}) => {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 23.023,
    lng: 72.572,
  });
  const [errors, setErrors] = useState({});
  const [editable, setEditable] = useState(true);

  const [formData, setFormData] = useState({
    geofence_name: "",
    zone: "",
    fleet: "",
    description: "",
    type: "",
    coordinates: "",
    id:"",
  });

  const theme = useTheme();
  const showSnackbar = useSnackbar();

  const { geofences } = useSelector((state) => state.geofencedata);
  const dispatch = useDispatch();
  const geofenceToEdit = geofences.find((g) => g.id === editGeofence);

  const { fleet } = useSelector((state) => state.fleetManage);

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown") {
      return;
    }
    setDrawerOpen(open);
    setFormData({
      geofence_name: "",
      zone: "",
      fleet: "",
      description: "",
      type: "",
      coordinates: "",
      id:""
    });
    setEditGeofence("")
    setEditable(true)
    setSelectedLocation({ lat: 23.023, lng: 72.572 });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.geofence_name.trim())
      newErrors.geofence_name = "This field is required";
    if (!formData.zone.trim()) newErrors.zone = "This field is required";
    if (!formData.fleet.trim()) newErrors.fleet = "This field is required";
    if (!formData.description.trim())
      newErrors.description = "This field is required";
    if (!formData.coordinates)
      newErrors.coordinates = "Please draw the geofence area on the map.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }

    if (name === "fleet") {
      const selectedFleet = fleetOptions.find((fleet) => fleet.value === value);
      if (selectedFleet) {
        setSelectedLocation({ lat: selectedFleet.lat, lng: selectedFleet.lng });
      } else {
        setSelectedLocation({ lat: 23.023, lng: 72.572 });
      }
    }
  };

  const fleetOptions = fleet?.map((fleet) => ({
    label: fleet.fleet_name,
    value: fleet.fleet_name,
    lat: fleet.location_lat,
    lng: fleet.location_lng,
  }));

  const handleSaveChanges = () => {
    if (validateForm()) {
      dispatch(addGeofenceData(formData));
      setDrawerOpen(false);
      setFormData({
        geofence_name: "",
        zone: "",
        fleet: "",
        description: "",
        type: "",
        coordinates: "",
        id:""
      });
      setEditGeofence("")
      setSelectedLocation({ lat: 23.023, lng: 72.572 });
      showSnackbar("Geofence added successfully!", "success");
    } else {
      // console.log("Validation errors", errors);
    }
  };

  const handlePolygonComplete = (polygon) => {
    const vertices = polygon?.getPath().getArray();
    const center = vertices.reduce(
      (acc, vertex) => {
        acc.lat += vertex.lat();
        acc.lng += vertex.lng();
        return acc;
      },
      { lat: 0, lng: 0 }
    );

    center.lat /= vertices.length;
    center.lng /= vertices.length;

    setFormData((prevFormData) => ({
      ...prevFormData,
      type: "polygon",
      coordinates: {
        path: vertices.map((p) => ({ lat: p.lat(), lng: p.lng() })),
        center: center,
      },
    }));
  };

  const handleCircleComplete = (circle) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      type: "circle",
      coordinates: {
        center: {
          lat: circle.getCenter().lat(),
          lng: circle.getCenter().lng(),
        },
        radius: circle.getRadius(),
      },
    }));
  };

  const handleLocationSelected = (latLng) => {
    setFormData((prevState) => ({
      ...prevState,
      type: "marker",
      coordinates: {
        center: {
          lat: latLng.lat,
          lng: latLng.lng,
        },
      },
    }));
  };

  const handleGeofenceDelete = () => {
    handleDelete(editGeofence);
  };

    const handleEditview = () => {
    setEditable(!editable);
  };

  useEffect(() => {
    if (editGeofence) {
      setEditable(false);
    }
  }, [editGeofence]);

  useEffect(() => {
    if (geofenceToEdit) {
      setFormData({
        geofence_name: geofenceToEdit?.geofence_name || "",
        zone: geofenceToEdit?.zone || "",
        fleet: geofenceToEdit?.fleet || "",
        description: geofenceToEdit?.description || "",
        type: geofenceToEdit?.type || "",
        coordinates: geofenceToEdit?.coordinates || "",
        id:geofenceToEdit?.id || "",
      });

      setSelectedLocation(geofenceToEdit.coordinates.center)
    }
  }, [geofenceToEdit]);

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={toggleDrawer(false)}
      transitionDuration={{
        enter: 500,
        exit: 500,
      }}
      PaperProps={{
        sx: {
          width: "80%",
          boxSizing: "border-box",
          background: theme.palette.text.quaternary,
          border: "none",
        },
      }}
      sx={{
        width: "80%",
        flexShrink: 0,
      }}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ flex: 1, width: "100%" }}>
          <MapComponent
            height={"100vh"}
            width={"100%"}
            enableDrawing={formData.zone === "Parking Zone" ? true : false}
            enablePinCustomZone={
              formData.zone && formData.zone !== "Parking Zone" ? true : false
            }
            onPolygonComplete={handlePolygonComplete}
            onCircleComplete={handleCircleComplete}
            onLocationSelected={handleLocationSelected}
            geofenceData={geofences}
            zoneType={formData.zone}
            center={selectedLocation}
          />
        </Box>

        <Box
          sx={{
            width: "30%",
            padding: "20px",
            backgroundColor: "#ffff",
            minHeight: "80vh",
            overflow: "auto",
          }}
          role="presentation"
          onKeyDown={toggleDrawer(false)}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography sx={{ fontSize: "1rem", mt: 0.5 }}>
             {editGeofence?"Edit Geofence":"New Geofence"}
            </Typography>
            <Box sx={{ display: "flex" }}>
              {editGeofence && (
                <>
                  <Box
                    onClick={handleEditview}
                    sx={{
                      border: "1px solid #DDDEE1",
                      borderRadius: "6px",
                      p: 0.2,
                      mr: 0.5,
                      ml: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip
                      title="Edit"
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#424242", // Dark grey background for high contrast
                            color: "white", // White text for clarity
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "10px 15px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#424242", // Matching the tooltip background
                          },
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          color: theme.palette.text.primary,
                          width: "25px",
                          height: "25px",
                        }}
                      >
                        <img
                          src={actionedit}
                          alt="edit"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box
                    onClick={handleGeofenceDelete}
                    sx={{
                      border: "1px solid #DDDEE1",
                      borderRadius: "6px",
                      p: 0.2,
                      mr: 0.5,
                      ml: 0.5,
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip
                      title="Delete"
                      placement="top"
                      arrow
                      componentsProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#424242", // Dark grey background for high contrast
                            color: "white", // White text for clarity
                            fontWeight: "600",
                            fontSize: "14px",
                            padding: "10px 15px",
                            borderRadius: "8px",
                            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)", // Slightly darker shadow for depth
                          },
                        },
                        arrow: {
                          sx: {
                            color: "#424242", // Matching the tooltip background
                          },
                        },
                      }}
                    >
                      <IconButton
                        sx={{
                          color: theme.palette.text.primary,
                          width: "25px",
                          height: "25px",
                        }}
                      >
                        <img
                          src={actiondelete}
                          alt="delete"
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
              <IconButton
                onClick={toggleDrawer(false)}
                sx={{ color: theme.palette.text.primary }}
              >
                <img src={crossicon} alt="Close" />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              flex: "1 1 auto",
            }}
          >
            <InputFieldWrapper
              label="Name"
              name="geofence_name"
              value={formData.geofence_name}
              onChange={handleInputChange}
              placeholder="Enter Geofence Name"
              error={errors.geofence_name}
              editable={editable}
            />
            <CustomSelect
              label="Zone Type"
              value={formData.zone}
              onChange={handleInputChange}
              options={[
                { value: "", label: "None" },
                { value: "Parking Zone", label: "Parking Zone" },
                { value: "Service Zone", label: "Service Zone" },
                { value: "Fuel Station Zone", label: "Fuel Station Zone" },
              ]}
              name="zone"
              error={errors.zone}
              editable={editable}
            />

            <CustomSelect
              label="Fleet"
              value={formData.fleet}
              onChange={handleInputChange}
              options={fleetOptions}
              name="fleet"
              error={errors.fleet}
              editable={editable}
            />

            <InputFieldWrapper
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter Description Name"
              error={errors.description}
              editable={editable}
            />
          </Box>
          {errors.coordinates && (
            <FormHelperText sx={{ color: "red", fontSize: "0.8rem" }}>
              {errors.coordinates}
            </FormHelperText>
          )}

          <Box sx={{ mt: { lg: 8, xl: 38 } }}>
            <FilledButton sx={{ width: "150px",'&.Mui-disabled': { 
                      color: 'white',
                      bgcolor: 'grey'
                    } }} onClick={handleSaveChanges} disabled={!editable}>
               {editGeofence?"Save Changes":"Create"}
            </FilledButton>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default GeofenceDrawer;
