import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Polygon,
  Marker,
  useLoadScript,
  DrawingManager,
  Circle,
  InfoWindow,
  OverlayView,
} from "@react-google-maps/api";
import CloseIcon from "@mui/icons-material/Close";
import parkingflag from "../../assets/Icons/parkingflag.svg";
import restrictedflag from "../../assets/Icons/restrictedflag.svg";
import slowspeedflag from "../../assets/Icons/slowspeedmap.svg";
import serviceflag from "../../assets/Icons/serviceflagmap.svg";
import fuelstationflag from "../../assets/Icons/fuelstation.svg";
import batterypercentage from "../../assets/Icons/batterypercentage.svg";
import dummypng from "../../assets/Images/dummy.png";

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import maplocation from "../../assets/Icons/maplocation.svg";
import truckicon from "../../assets/Icons/truckIcon.svg";
import carIcon from "../../assets/Icons/carIcon.svg";
import scooterIcon from "../../assets/Icons/scooterIcon.svg";
import VehicleModal from "../../features/MapPage/VehicleModal";
import { useNavigate } from "react-router-dom";

export const nightModeStyle = [
  {
    elementType: "geometry",
    stylers: [{ color: "#212121" }],
  },
  {
    elementType: "labels.text.fill",
    stylers: [{ color: "#757575" }],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{ color: "#212121" }],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9e9e9e" }],
  },
  {
    featureType: "administrative.land_parcel",
    stylers: [{ visibility: "off" }],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#bdbdbd" }],
  },
  {
    featureType: "poi",
    stylers: [{ visibility: "off" }], // Hide all POIs
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#181818" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#1b1b1b" }],
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [{ color: "#2c2c2c" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#8a8a8a" }],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{ color: "#373737" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#3c3c3c" }],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [{ color: "#4e4e4e" }],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [{ color: "#616161" }],
  },
  {
    featureType: "transit",
    stylers: [{ visibility: "off" }], // Hide all transit icons
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#000000" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#3d3d3d" }],
  },
];

const zoneColors = {
  // "Restricted Zone": "#FF000033",
  "Parking Zone": "#0077CC",
  "Service Zone": "#2D8C55",
  "Fuel Station Zone": "#6D00CC",
};

// Define icon URLs based on flag types
const zoneIcons = {
  "Parking Zone": parkingflag,
  // "Restricted Zone": restrictedflag,
  "Service Zone": serviceflag,
  "Fuel Station Zone": fuelstationflag,
};

const getPolygonCentroid = (coords) => {
  let x = 0,
    y = 0,
    n = coords.length;
  for (let i = 0; i < n; i++) {
    x += coords[i].lng();
    y += coords[i].lat();
  }
  return { lat: y / n, lng: x / n };
};

export const MapComponent = ({
  width,
  height,
  zoneType,
  selectedFleet,
  selectedFlag,
  markerlocation,
  livelocation = false,
  geofenceData,
  center = { lat: 23.023, lng: 72.572 },
  enablePin = false,
  enablePinCustomZone = false,
  onLocationSelected,
  enableDrawing = false,
  onPolygonComplete,
  onCircleComplete,
  vehicleList,
  zoomlevel,
}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDiytvXP2dWy_3E-rJX2NWwnnFWZe4aSjU",
    libraries: ["drawing"],
  });

  const [markerPosition, setMarkerPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 23.023, lng: 72.572 });
  const [currentShape, setCurrentShape] = useState(null);
  const [isUserInteraction, setIsUserInteraction] = useState(false);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null); // Position of the InfoWindow

  const [selectedVehicle, setSelectedVehicle] = useState(null); // State for selected vehicle
  const [open, setOpen] = useState(false); // State for modal visibility
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (zoneType !== "Parking Zone") {
      currentShape?.setMap(null);
      setMarkerPosition(null);
      setCurrentShape(null);
      // onPolygonComplete(null)
      // onCircleComplete(null)
    }
  }, [zoneType]);

  useEffect(() => {
    // Prevent updating the center if pinning is enabled
    if (enablePin) return;
    if (center.lat !== mapCenter.lat || center.lng !== mapCenter.lng) {
      setMapCenter(center);
    }
  }, [center, mapCenter, enablePin, enablePinCustomZone]);

  // useEffect(() => {
  //   // Prevent updating the center if pinning is enabled
  //   if (enablePinCustomZone) return;
  //   if (center.lat !== mapCenter.lat || center.lng !== mapCenter.lng) {
  //     setMapCenter(center);
  //   }
  // }, [center, mapCenter, enablePin, enablePinCustomZone]);

  if (!isLoaded) return <div>Loading...</div>;

  const getPolygonOptions = (zone) => ({
    fillColor: zoneColors[zone] || "#FF0000", // Use the color specified for the zone
    fillOpacity: 0.75, // Set a noticeable fill opacity
    strokeColor: zoneColors[zone] || "#FF0000", // Use the same color for the border
    strokeOpacity: 1, // Full opacity for the border
    strokeWeight: 2, // Set the border width
    clickable: true, // Make the polygon clickable
    editable: false, // Prevent editing
    zIndex: 1, // Set the z-index if needed for layering
  });

  const getCircleOptions = (zone) => ({
    fillColor: zoneColors[zone] || "#FF0000",
    fillOpacity: 0.5,
    strokeColor: zoneColors[zone], // Set the border color to white
    strokeOpacity: 1, // Full opacity for the border
    strokeWeight: 2,
    strokePattern: [
      { icon: "dot", offset: "0", repeat: "10px" }, // Define the pattern as dotted
    ],
    clickable: true,
    editable: false,
    zIndex: 1,
  });

  const drawingOptions = {
    drawingControl: enableDrawing,
    drawingControlOptions: {
      position: window.google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ["polygon", "circle"],
    },
    polygonOptions: getPolygonOptions(zoneType), // Apply dynamic color based on zoneType
    circleOptions: getCircleOptions(zoneType), // Apply the same for circles
  };

  const handlePolygonComplete = (polygon) => {
    if (currentShape) {
      currentShape.setMap(null);
    }
    setCurrentShape(polygon);

    const polygonPath = polygon.getPath().getArray();
    const centroid = getPolygonCentroid(polygonPath);
    setMarkerPosition(centroid);

    if (onPolygonComplete) {
      onPolygonComplete(polygon);
    }
  };

  const handleCircleComplete = (circle) => {
    if (currentShape) {
      currentShape.setMap(null);
    }
    setCurrentShape(circle);

    const circleCenter = circle.getCenter();
    setMarkerPosition({ lat: circleCenter.lat(), lng: circleCenter.lng() });

    if (onCircleComplete) {
      onCircleComplete(circle);
    }
  };

  const handleMapClick = (event) => {
    if (!enablePin && !enablePinCustomZone) return; // Prevent pinning if the prop is false

    // Get lat and lng from the click event
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    //     // Update the marker position
    setMarkerPosition({ lat, lng });
    setMapCenter({ lat, lng });
    setIsUserInteraction(true);
    // Call the callback function passed from the parent to send lat/lng
    if (onLocationSelected) {
      onLocationSelected({ lat, lng });
    }
  };

  // Filter geofences based on fleet and flag
  const filteredGeofences = geofenceData?.filter((geofence) => {
    return (
      (!selectedFleet || geofence.fleet === selectedFleet) &&
      (!selectedFlag || geofence.flag === selectedFlag)
    );
  });

  const handleMarkerClick = (vehicle) => {
    setSelectedVehicle(vehicle); // Store the clicked vehicle details
    setInfoWindowPosition({ lat: vehicle.lat, lng: vehicle.lng }); // Set the position of the InfoWindow
  };

  const handleInfoWindowClose = () => {
    setSelectedVehicle(null); // Close the InfoWindow
    setInfoWindowPosition(null); // Reset position
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    setSelectedVehicle(null); // Reset selected vehicle
  };

  return (
    <Box
      sx={{
        height: height,
        width: width,
        border: "1px solid #ccc",
        borderRadius: "1rem",
        mt: 1,
        mb: 1,
      }}
    >
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
          borderRadius: "1rem",
        }}
        center={mapCenter}
        zoom={zoomlevel ? zoomlevel : 14}
        options={{
          styles: nightModeStyle, // Assuming you have night mode styles
          mapTypeControl: false, // Hides Map/Satellite view options
          // streetViewControl: false, // Hides the Pegman (Street View control)
        }}
        onClick={enablePin || enablePinCustomZone ? handleMapClick : null}
      >
        {markerPosition && enablePin && (
          <>
            <Marker
              position={markerPosition}
              icon={{
                url: maplocation, // Use appropriate flag icon
                scaledSize: new window.google.maps.Size(60, 60), // Icon size
              }}
            />
          </>
        )}
        {markerPosition && enablePinCustomZone && (
          <>
            <Marker
              position={markerPosition}
              icon={{
                url: zoneIcons[zoneType],
                scaledSize: new window.google.maps.Size(30, 30),
              }}
              label={{
                text: zoneType,
                color: "#FFFFFF",
                fontSize: "12px",
              }}
            />
          </>
        )}
        {filteredGeofences?.map((geofence) => {
          return (
            <React.Fragment key={geofence.id}>
              {geofence.type === "polygon" ? (
                <Polygon
                  paths={geofence.coordinates.path}
                  options={getPolygonOptions(geofence.zone)}
                />
              ) : geofence.type === "circle" ? (
                <Circle
                  center={geofence.coordinates.center}
                  radius={geofence.coordinates.radius}
                  options={getCircleOptions(geofence.zone)}
                />
              ) : geofence.type === "marker" ? (
                <Marker
                  position={geofence.coordinates.center}
                  icon={{
                    url: zoneIcons[geofence.zone],
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                  label={{
                    text: geofence.name,
                    color: "#FFFFFF",
                    fontSize: "12px",
                  }}
                />
              ) : null}
              <Marker
                position={geofence.coordinates.center}
                icon={{
                  url: zoneIcons[geofence.zone],
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                label={{
                  text: geofence.name,
                  color: "#FFFFFF",
                  fontSize: "12px",
                }}
              />
            </React.Fragment>
          );
        })}

        {enableDrawing && (
          <DrawingManager
            options={drawingOptions}
            onPolygonComplete={handlePolygonComplete}
            onCircleComplete={handleCircleComplete}
          />
        )}

        {markerPosition && enableDrawing && (
          <Marker
            position={markerPosition}
            icon={{
              url: zoneIcons[zoneType], // Dynamically set based on zoneType
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        )}

        {markerlocation && livelocation && (
          <Marker
            position={markerlocation}
            icon={{
              url: maplocation, // Dynamically set based on zoneType
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        )}
        {vehicleList?.map((location) => {
          return (
            <>
              <Marker
                position={{ lat: location.lat, lng: location.lng }}
                icon={{
                  url:
                    location.type === "car"
                      ? carIcon
                      : location.type === "truck"
                      ? truckicon
                      : scooterIcon,
                  scaledSize: new window.google.maps.Size(30, 30),
                }}
                onClick={() => handleMarkerClick(location)} // Handle marker click
              />
            </>
          );
        })}
        {selectedVehicle && infoWindowPosition && (
          <OverlayView
            position={infoWindowPosition}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} // Ensures it's clickable
            getPixelPositionOffset={(width, height) => ({ x: -(width / 2), y: -height })} // Center the overlay and adjust it to appear above the marker

          >
            <div
              style={{
                width: "350px",
                borderRadius: "10px",
                overflow: "hidden",
                boxShadow: "0 4px 12px rgba(0,0,0,0.3)",
                fontFamily: '"Roboto", sans-serif',
                fontSize: "14px",
                backgroundColor: "white",
                position: "relative",
              }}
            >
              {/* Custom Header with Close Button */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px 15px",
                  backgroundColor: "#ff6961",
                  color: "white",
                  fontWeight: 500,
                  alignItems: "center",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                }}
              >
                <Typography sx={{fontWeight:500,fontSize:'1rem'}}>Vehicle Details</Typography>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleInfoWindowClose}
                />
              </div>

              {/* Body */}
              <div style={{ padding: "15px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={scooterIcon}
                    alt="vehicle icon"
                    style={{
                      width: "40px",
                      height: "40px",
                      marginRight: "12px",
                    }}
                  />
                  <div>
                    <h3
                      style={{
                        margin: "0 0 10px 0", // Add more space below the vehicle name
                        color: "#ff6961",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: 400,
                      }}
                      onClick={() =>
                        navigate(`/dashboard/vehicles/${selectedVehicle.id}`)
                      }
                    >
                      {selectedVehicle.vehicle_name}
                    </h3>
                    <p style={{ margin: "8px 0", color: "#333" }}>
                      Fleet: {selectedVehicle.fleet}
                    </p>{" "}
                    <p style={{ margin: "8px 0", color: "#333" }}>
                      IMEI: {selectedVehicle.imei}
                    </p>{" "}
                  </div>
                </div>

                {/* Driver, Battery, and Last Active */}
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "15px",
                    marginTop: "15px",
                    paddingTop: "10px",
                    borderTop: "2px solid #ddd",
                  }}
                >
                  <div>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: 400,
                        color: "#FE7676",
                        fontSize: "0.9rem",
                      }}
                    >
                      Driver
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={dummypng}
                        alt="driver avatar"
                        style={{
                          width: "24px",
                          height: "24px",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      />
                      <p
                        style={{
                          margin: "0",
                          color: "#283246",
                          fontWeight: 400,
                          fontSize: "0.9rem",
                        }}
                      >
                        {selectedVehicle.driver || "N/A"}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: 400,
                        color: "#FE7676",
                        fontSize: "0.9rem",
                      }}
                    >
                      Status
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#FFE4E4",
                        padding: "4px 12px",
                        borderRadius: "20px",
                        color: "#FE7676",
                        fontSize: "0.9rem",
                        justifyContent: "center",
                        width:'50%'
                      }}
                    >
                      <span
                        style={{
                          marginRight: "5px",
                          width: "6px",
                          height: "6px",
                          backgroundColor: "#FE7676",
                          borderRadius: "50%",
                        }}
                      ></span>
                      In Transit
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: 400,
                        color: "#FE7676",
                        fontSize: "0.9rem",
                      }}
                    >
                      Battery
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={batterypercentage}
                        alt="battery icon"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "5px",
                        }}
                      />
                      <p
                        style={{
                          margin: "0",
                          color: "#283246",
                          fontWeight: 400,
                          fontSize: "0.9rem",
                        }}
                      >
                        {selectedVehicle.battery || "N/A"}% Charged
                      </p>
                    </div>
                  </div>
                  <div>
                    <p
                      style={{
                        margin: "0",
                        fontWeight: 400,
                        color: "#FE7676",
                        fontSize: "0.9rem",
                      }}
                    >
                      Last Active
                    </p>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          marginRight: "5px",
                          width: "6px",
                          height: "6px",
                          backgroundColor: "#52C37F",
                          borderRadius: "50%",
                        }}
                      ></span>
                      <p
                        style={{
                          margin: "0",
                          color: "#283246",
                          fontWeight: 400,
                          fontSize: "0.9rem",
                        }}
                      >
                        {selectedVehicle.last_active || "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OverlayView>
        )}
      </GoogleMap>
    </Box>
  );
};
